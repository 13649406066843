export enum ENUM_COMMON_STATUS {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED',
    SUSPENDED = 'SUSPENDED'
}

export enum ENUM_BACKGROUND_OPTION {
    SKYBOX = 'SKYBOX',
    IMAGE = 'IMAGE',
    COLOR = 'COLOR'
}

export enum ENUM_CHAT_BY {
    USER = 'user',
    ASSISTANT = 'assistant',
}

export enum SUBSCRIPTION_STATUS {
    CREATED = 'created',
    INCOMPLETE = 'incomplete',
    INCOMPLETE_EXPIRED = 'incomplete_expired',
    TRIALING = 'trialing',
    ACTIVE = 'active',
    PAST_DUE = 'past_due',
    CANCELED = 'canceled',
    UNPAID = 'unpaid'
}