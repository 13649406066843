import { FC } from 'react'
import { Spinner } from 'react-bootstrap'

interface LoaderProps {
    className?: string
}
export const Loader: FC<LoaderProps> = ({ className }) => {
    return (
        <div className={`position-absolute w-100 h-100 translate-middle top-50 start-50 overflow-hidden z-3 ${className ?? 'bg-dark'}`}>
            <div className="position-absolute translate-middle top-50 start-50">
                <Spinner className='text-white' />
            </div>
        </div>
    )
}