import useAvatarRefs from './RefContext/useAvatarRefs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from '@fortawesome/free-solid-svg-icons';

const StopSpeakingButton = () => {

    const { isSpeaking, stopSpeaking } = useAvatarRefs();

    if (!isSpeaking) {
        return <></>
    }
    return (

        <button
            title="stop speaking"
            disabled={!isSpeaking}
            aria-label="stop speaking"
            className="btn bg-primary-subtle d-flex align-items-center justify-content-center gap-2 shadow-sm"
            onClick={stopSpeaking}
        >
            <span className="d-none d-md-block">Stop speaking</span>
            <span className="d-md-none">
                <FontAwesomeIcon icon={faStop} />
            </span>
        </button>
    )
}

export default StopSpeakingButton