import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';

function App() {
  const { user, admin } = useSelector((state: RootState) => state.auth);
  let token = user?.token || admin?.token;

  return (
    <BrowserRouter>
      <div className="App bg-dark">
        <ToastContainer
          position="top-right"
          autoClose={2500}
        />
        {!token && <PublicRoutes />}
        {token && <PrivateRoutes />}
      </div>
    </BrowserRouter>
  );
}

export default App;
