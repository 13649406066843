import React, { createContext, useRef, useCallback, ReactNode, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Humanoid } from 'src/common/Babylon/Humanoid';


interface RefsContextType {
    humanoidRef: React.RefObject<Humanoid | null>;
    currentSoundRef: React.RefObject<HTMLAudioElement | null>;
    setHumanoidRef: (humanoid: Humanoid | null) => void;
    destroyHumanoidRef: () => void;
    setCurrentSoundRef: (audio: HTMLAudioElement | null) => void;
    isSpeaking: boolean;
    setIsSpeaking: (value: boolean) => void;
    stopSpeaking: () => void
}

const RefsContext = createContext<RefsContextType | null>(null);

const RefsProvider = ({ children }: { children: ReactNode }) => {
    const { resetTranscript } = useSpeechRecognition();

    const humanoidRef = useRef<Humanoid | null>(null);
    const currentSoundRef = useRef<HTMLAudioElement | null>(null);
    const [isSpeaking, setIsSpeaking] = useState(false);


    const setHumanoidRef = useCallback((humanoid: Humanoid | null) => {
        humanoidRef.current?.dispose()
        humanoidRef.current = humanoid;
    }, []);

    const destroyHumanoidRef = useCallback(() => {
        humanoidRef.current?.dispose()
    }, []);

    const setCurrentSoundRef = useCallback((audio: HTMLAudioElement | null) => {
        currentSoundRef.current = audio;
    }, []);

    const stopSpeaking = async (): Promise<void> => {
        if (currentSoundRef.current) {
            currentSoundRef.current.pause();
            setCurrentSoundRef(null);
        }
        humanoidRef.current?.talkAnimationEnd("manually stopped");
        setIsSpeaking(false)
        await SpeechRecognition.abortListening();
        resetTranscript();
    };

    return (
        <RefsContext.Provider value={{ humanoidRef, currentSoundRef, setHumanoidRef, destroyHumanoidRef, setCurrentSoundRef, isSpeaking, setIsSpeaking, stopSpeaking }}>
            {children}
        </RefsContext.Provider>
    );
};

export { RefsProvider, RefsContext };
