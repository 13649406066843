import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { SEND_MESSAGE, CONVERSATION, GET_CONVERSATION_GROUP_BY_THREAD, GET_CONVERSATION_THREAD_MESSAGES, INIT_MESSAGE } from 'src/constants/constants';
import { ASSISTANT, IChatMessage, ISendMessageData } from 'src/pages/Conversation/types/conversation.type';
import { PaginationData } from 'src/types/types';

// Define type
export interface ChatState {
    messages: IChatMessage[]
    isLoadingMessage: boolean
    errorMessage: string | null
    charCount: number
    abortController?: AbortController
    lastBotResponse: string | null
    threadId: string | null
    limitExceeds: boolean;
};

// Define the initial state
const initialState: ChatState = {
    messages: [],
    isLoadingMessage: false,
    errorMessage: null,
    charCount: 0, // textAreaValue
    abortController: undefined,
    lastBotResponse: null,
    threadId: null,
    limitExceeds: false
};

// Create async thunks for authentication operations
export const sendMessageHandler = createAsyncThunk(
    SEND_MESSAGE,
    async (data: ISendMessageData, { rejectWithValue }) => {
        try {
            const response = await api.post(`${CONVERSATION}/send-message`, data);
            return response?.data;
        } catch (error: any) {
            return rejectWithValue(error?.data);
        }
    }
);

export const initMessageHandler = createAsyncThunk(
    INIT_MESSAGE,
    async (assistantId: string, { rejectWithValue }) => {
        try {
            const response = await api.post(`${CONVERSATION}/init-message`, { assistantId });
            return response?.data;
        } catch (error: any) {
            return rejectWithValue(error?.data);
        }
    }
);

export const getAllConversationsGroupByThread = createAsyncThunk(
    GET_CONVERSATION_GROUP_BY_THREAD,
    async (data: PaginationData, { rejectWithValue }) => {
        try {
            const response = await api.get(`${CONVERSATION}/group-by-thread`, {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getConversationThreadMessages = createAsyncThunk(
    GET_CONVERSATION_THREAD_MESSAGES,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${CONVERSATION}/by-thread-id/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }

)


// Create the slice
const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        handleThreadId: (state, { payload }: PayloadAction<string | null>) => {
            state.threadId = payload;
        },
        handleChatErrorMessage: (state, { payload }: PayloadAction<string | null>) => {
            state.errorMessage = payload;
        },
        handleAbortController: (state, { payload }: PayloadAction<AbortController>) => {
            state.abortController = payload;
        },
        handleIsLoadingMessage: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoadingMessage = payload;
        },
        handleCharCount: (state, { payload }: PayloadAction<string>) => {
            state.charCount = payload.length;
        },
        handleInitialMessage: (state, { payload }: PayloadAction<string>) => {
            state.messages = [
                {
                    content: payload,
                    role: ASSISTANT,
                }
            ];
        },
        handleNewMessage: (state, { payload }: PayloadAction<IChatMessage>) => {
            const { content, role } = payload
            const lastMessageRole = state.messages?.at(-1)?.role;
            if (lastMessageRole === ASSISTANT && role === ASSISTANT) {

                state.messages[state.messages.length - 1] = payload;

                // If a server message is done loading, set lastLLMResponse.
                if (!state.isLoadingMessage && !state.errorMessage) {
                    state.lastBotResponse = content;
                }

            } else {
                state.errorMessage = "";
                state.messages.push(payload);
            }
        },
        resetMessageHandler: (state) => {
            state.messages = []
        },
        toggleLimitExceeds: (state, { payload }: PayloadAction<boolean>) => {
            state.limitExceeds = payload;
        }
        // Optional synchronous actions can be defined here
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessageHandler.pending, (state) => {

            })
            .addCase(sendMessageHandler.fulfilled, (state, action) => {
                // if (!state.threadId) {
                //     state.threadId = action.payload.data.threadId
                // }
            })
            .addCase(sendMessageHandler.rejected, (state, action: PayloadAction<any>) => {
                state.errorMessage = "Something went wrong."
                state.limitExceeds = action?.payload?.limitExceeds;
            })
            .addCase(initMessageHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
    },
});

export const { handleThreadId, handleChatErrorMessage, handleAbortController, handleCharCount, handleIsLoadingMessage, handleInitialMessage, handleNewMessage, resetMessageHandler, toggleLimitExceeds } = conversationSlice.actions

export default conversationSlice.reducer;
