import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getLoggedInUserDetails } from "./userSlice";
import { SUBSCRIPTION_STATUS } from "src/types/enum";
import { SubscriptionDate } from "src/pages/Subscription/type/subscription.type";

export interface ISubscription {
    amount: string | null;
    status: SUBSCRIPTION_STATUS | null;
    temporaryExpiredAt?: SubscriptionDate;
    actualStartDate: SubscriptionDate;
    cancellationDate: SubscriptionDate;
    currentPeriodStartDate: SubscriptionDate;
    currentPeriodEndDate: SubscriptionDate;
    freeTrialEndDate: SubscriptionDate;
    isLoading: boolean;
}

const initialState: ISubscription = {
    amount: null,
    status: null,
    temporaryExpiredAt: null,
    actualStartDate: null,
    cancellationDate: null,
    currentPeriodEndDate: null,
    currentPeriodStartDate: null,
    freeTrialEndDate: null,
    isLoading: false
};

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        setSubscriptionDetails: (state, { payload }: PayloadAction<Partial<ISubscription>>) => {
            return (state = {
                ...state,
                ...payload
            });
        }
    },
    extraReducers: (builder) => {
        // Set subscription data
        builder
            .addCase(getLoggedInUserDetails.pending, (state) => {
                return (state = {
                    ...state,
                    isLoading: true
                });
            })
            .addCase(getLoggedInUserDetails.fulfilled, (state, { payload }: PayloadAction<{ subscription: ISubscription }>) => {
                return (state = {
                    ...state,
                    ...payload?.subscription,
                    isLoading: false,
                });
            })
            .addCase(getLoggedInUserDetails.rejected, (state, { payload }: PayloadAction<any>) => {
                // toast.error(payload);
                return (state = {
                    ...state,
                    isLoading: false,
                });
            })
    }
});

export const {
    setSubscriptionDetails
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
export const getSubscriptionDetails = (state: RootState) => state?.subscription;