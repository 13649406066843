export const APPNAME = 'BIVOBOT'
export const API_URL = process.env.REACT_APP_API_URL

//slice name and api end point
export const DASHBOARD = '/dashboard'

export const AVATARCANVAS = '/avatar-canvas'
export const PROFILE = '/profile'
export const SUBSCRIPTION = '/subscription'
export const NOT_FOUND = 'not-found'

//response types
export const IDLE = 'idle'
export const PENDING = '/pending'
export const FULFILLED = '/fulfilled'
export const REJECTED = '/rejected'


//router paths and asyncThunk actions
// pages
export const AUTH = '/auth'
export const SIGNUP = '/signup';
export const SIGNIN = '/signin';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const CHANGE_PASSWORD = '/change-password';
export const TENANT_LISTING = '/tenant';
export const USER_LISTING = '/user';
export const CONVERSATION_LISTING = '/conversation';
export const ROLE_PERMISSIONS_LISTING = '/role-permission';
export const AVATAR_LISTING = '/avatar';

//api endpoints and asyncThunk actions
export const TENANT = '/tenant'
export const GET_ALL_TENANTS = '/get-all-tenants'
export const GET_TENANT_BY_ID = '/get-tenant-by-id'
export const CREATE_TENANT = '/create-tenant'
export const UPDATE_TENANT = '/update-tenant'
export const DELETE_TENANT = '/delete-tenant'
export const LOGOUT_USER = '/logout';


export const USER = '/user'
export const GET_ALL_USERS = '/get-all-users'
export const GET_USER_BY_ID = '/get-user-by-id'
export const CREATE_USER = '/create-user'
export const UPDATE_USER = '/update-user'
export const DELETE_USER = '/delete-user'
export const UPDATE_TENANT_KEYS = '/update-tenant-keys'
export const LOGGED_IN_USER = '/me';
export const CHANGE_PASSWORD_IN = '/change-password-in';

export const AWS = '/aws'
export const UPLOAD_TO_S3 = '/upload-to-s3'

export const AVATAR = '/avatar'
export const GET_ALL_AVATARS = '/get-all-avatars'
export const GET_ALL_ADMIN_AVATARS = '/get-all-admin-avatars'
export const GET_AVATAR_BY_ID = '/get-avatar-by-id'
export const CREATE_AVATAR = '/create-avatar'
export const UPDATE_AVATAR = '/update-avatar'
export const DELETE_AVATAR = '/delete-avatar'
export const AVATAR_LABEL_VALUE = '/avatar-label-value'
export const VALIDATE_AVATAR_PWD = '/validate-avatar-pwd'
export const CHECK_FOR_AVATAR_PWD = '/check_for-avatar-pwd'
export const GET_AVATAR_UPDATE_TIME = '/get-avatar-update-time'

export const ROLE = "/role"
export const PERMISSION = "permission"
export const GET_ALL_ROLES_AND_PERMS = "get-all-roles-and-perms"
export const GET_ALL_ROLES = "get-all-roles"
export const CREATE_ROLE = '/create-role'
export const GET_ROLES_AND_PERMS = "get-roles-and-perms"
export const UPDATE_ROLES_AND_PERMS = "update-roles-and-permissions"
export const DELETE_ROLE = '/delete-role'
export const GET_ALL_PERMISSIONS = "get-all-permissions"


export const CONVERSATION = "/conversation"
export const SEND_MESSAGE = '/send-message'
export const INIT_MESSAGE = '/init-message'
export const GET_CONVERSATION_GROUP_BY_THREAD = '/group-by-thread';
export const GET_CONVERSATION_THREAD_MESSAGES = '/conversation-thread-messages'


export const STRIPE_PAYMENT = '/payment';
export const TENANT_SUBSCRIPTION_CREATE = '/create-tenant-subscription';


export const VIDEO = '/human-avatar'
export const GET_ALL_VIDEOS = "/get-all-videos"
export const GET_VIDEO_BY_ID = "/get-video-by-videoid"
export const CREATE_VIDEO = '/create-video'
export const DELETE_VIDEO = '/delete-video'
export const CHECK_VIDEO_STATUS = '/check-video-status'