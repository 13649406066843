import {
  Animation,
  ArcRotateCamera,
  AxesViewer,
  IAnimationKey,
  MorphTarget,
  MorphTargetManager,
  Scene,
  Vector3,
} from "babylonjs";
import { MAIN_CAMERA_NAME } from "src/config/modelConfig";
import { CameraConfig } from "src/pages/Avatar/types/avatar.type";

export const v3 = (x: number = 0, y: number = 0, z: number = 0): Vector3 => {
  return new Vector3(x, y, z);
};

export const getMorphTargetNames = (morphTargetManager: MorphTargetManager): string[] => {
  const morphTargetNames: string[] = [];

  for (let i = 0; i < morphTargetManager.numTargets; i++) {
    morphTargetNames.push(morphTargetManager.getTarget(i).name);
  }

  // console.log("all morphTargetNames", morphTargetNames);
  return morphTargetNames;
};

export const playMorphTargetAnim = (
  name: string,
  durationsSeconds: number[],
  values: number[],
  morphTarget: MorphTarget,
  endCallback: () => void,
  scene: Scene
) => {
  const keyFrames: IAnimationKey[] = [];
  const framesPerSecond = 60;

  let previousFrame = 0;
  for (let i = 0; i < values.length; i++) {
    const currentFrame = previousFrame + durationsSeconds[i] * framesPerSecond;

    keyFrames.push({
      frame: currentFrame,
      value: values[i],
    });

    previousFrame = currentFrame;
  }

  const lastFrame = keyFrames.at(-1);

  var morphAnimation = new Animation(
    name,
    "influence",
    framesPerSecond,
    Animation.ANIMATIONTYPE_FLOAT
  );
  morphAnimation.setKeys(keyFrames);

  morphTarget.animations = [morphAnimation];

  if (!lastFrame) {
    throw new Error("lastFrame is undefined");
  }

  scene.beginAnimation(morphTarget, 0, lastFrame.frame, false, 1, endCallback);
};

export const updateCamera = (cameraConfig: CameraConfig, scene: Scene | null) => {
  if (scene) {
    // Iterate through all cameras in the scene
    scene.cameras.forEach((camera) => {
      // Check if the camera is of type ArcRotateCamera and has the expected name
      if (camera.name === MAIN_CAMERA_NAME && camera instanceof ArcRotateCamera) {
        // Safely cast the camera to ArcRotateCamera type
        const arcRotateCamera = camera as ArcRotateCamera;

        // Update the camera properties
        arcRotateCamera.alpha = eval(cameraConfig.alpha);
        arcRotateCamera.beta = eval(cameraConfig.beta);
        arcRotateCamera.radius = cameraConfig.radius;
        // arcRotateCamera.target = cameraConfig.target;
        arcRotateCamera.target = v3(cameraConfig.positionX, cameraConfig.positionY, cameraConfig.positionZ);

        // Disable collisions temporarily to compute the world matrix
        arcRotateCamera.checkCollisions = false;
        arcRotateCamera.computeWorldMatrix();
        arcRotateCamera.checkCollisions = true;

        // For debugging: expose the camera to the window object
        // @ts-ignore
        window.camera = arcRotateCamera;
      }
    });
  }
};

export const getCameraPos = (scene: Scene | null) => {
  if (scene) {
    // Iterate through all cameras in the scene
    const camera = scene.cameras[0]
    // Check if the camera is of type ArcRotateCamera and has the expected name
    if (camera.name === MAIN_CAMERA_NAME && camera instanceof ArcRotateCamera) {
      // Safely cast the camera to ArcRotateCamera type
      const target = camera.getTarget()
      const details: CameraConfig = {
        alpha: camera.alpha.toString(),
        beta: camera.beta.toString(),
        radius: camera.radius,
        positionX: target.x,
        positionY: target.y,
        positionZ: target.z,
      }
      console.table(details)
      return details
    }
  }
};

export const getMorphTargetIndex = (
  morphTargetManager: MorphTargetManager,
  targetName: string
): number => {
  for (let i = 0; i < morphTargetManager.numTargets; i++) {
    const target = morphTargetManager.getTarget(i);
    // console.log(`matching morph target.name - ${target.name}==${targetName} `);
    if (target.name.toLowerCase().trim() === targetName.toLowerCase().trim()) {
      return i;
    }
  }

  return -1;
};
const setAxisViewer = (camera: ArcRotateCamera, position: Vector3, axesViewer: AxesViewer) => {
  axesViewer.update(
    position,
    camera.getDirection(v3(1, 0, 0)),
    camera.getDirection(v3(0, 1, 0)),
    camera.getDirection(v3(0, 0, 1))
  );
};

type AxesViewerAndAxesCamera = {
  axesViewer: AxesViewer;
  axesCamera: ArcRotateCamera;
};

const createAxesViewerAndAxesCamera = (scene: Scene): AxesViewerAndAxesCamera => {
  const axesViewer = new AxesViewer(scene, 2);
  var axesCamera = new ArcRotateCamera("AxesCamera", Math.PI / 2, 0, 8, v3(), scene);

  const axesCameraViewportSize = 0.15;
  axesCamera.viewport = new BABYLON.Viewport(
    1 - axesCameraViewportSize,
    0,
    axesCameraViewportSize,
    axesCameraViewportSize
  );

  axesViewer.xAxis.getChildMeshes().forEach((mesh) => {
    mesh.layerMask = 0x10000000;
  });
  axesViewer.yAxis.getChildMeshes().forEach((mesh) => {
    mesh.layerMask = 0x10000000;
  });
  axesViewer.zAxis.getChildMeshes().forEach((mesh) => {
    mesh.layerMask = 0x10000000;
  });

  // Restricts the camera to viewing objects with the same layerMask.
  axesCamera.layerMask = 0x10000000;

  return { axesCamera, axesViewer };
};

export const createCamera = (scene: Scene, cameraConfig: CameraConfig): ArcRotateCamera => {
  const camera = new ArcRotateCamera(
    MAIN_CAMERA_NAME,
    eval(cameraConfig.alpha),
    eval(cameraConfig.beta),
    cameraConfig.radius,
    // cameraConfig.target,
    v3(cameraConfig.positionX, cameraConfig.positionY, cameraConfig.positionZ),
    scene
  );
  camera.lowerRadiusLimit = 2;
  camera.upperRadiusLimit = 7;
  camera.upperBetaLimit = Math.PI / 1.5;

  camera.wheelPrecision = 20;

  return camera;
};

export const createCameraWithAxesInCorner = (
  scene: Scene,
  cameraConfig: CameraConfig
): ArcRotateCamera => {
  const camera = createCamera(scene, cameraConfig);

  // This is to hide the axes viewer. The axes viewer should only be visible by the axes camera, not this camera.
  camera.layerMask = 0x0fffffff;
  //camera.viewport = new BABYLON.Viewport(0.5, 0, 0.5, 1.0);

  const { axesViewer, axesCamera } = createAxesViewerAndAxesCamera(scene);

  //setAxisViewer(camera, v3(), axesViewer);

  camera.onViewMatrixChangedObservable.add(() => {
    setAxisViewer(camera, v3(), axesViewer);
  });

  if (!scene.activeCameras) {
    console.error("No active camera found :(");
    return camera;
  }

  scene.activeCameras.push(camera);
  scene.activeCameras.push(axesCamera);

  return camera;

  /*

TODO: 
- Use the following approach, instead of the above one.

export const createCameraWithAxesInCorner = (scene: Scene): ArcRotateCamera => {
  const camera = new ArcRotateCamera("Steve", Math.PI / 2, 0, 4, v3(), scene);
  const axesViewer = new AxesViewer(scene, 0.5);
  const axesViewerParent = new TransformNode("axesViewerParent");
  axesViewerParent.parent = camera;

  axesViewer.xAxis.parent = axesViewerParent;
  axesViewer.yAxis.parent = axesViewerParent;
  axesViewer.zAxis.parent = axesViewerParent;

  axesViewerParent.position = v3(1, 0, 2);

  scene.registerBeforeRender(function () {
    if (!scene.activeCamera) {
      console.error("No active camera found :(");
      return;
    }

    axesViewerParent.rotationQuaternion = scene.activeCamera.absoluteRotation;
  });

  return camera;
};

*/
};


export function isBabylonInspectorShowing() {
  return (
    process.env.NODE_ENV === "development" &&
    typeof window !== "undefined" &&
    document.getElementById("sceneExplorer")
  );
}

