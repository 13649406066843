import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "src/store/store";
import Chat from "./Chat/Chat";
// import WebGLNotSupported from "./Chat/WebGLNotSupported";
import { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { checkForAvatarPwdHandler, getAvatarUpdateTimeHandler, resetAvatarDataHandler, setAvatarDataHandler, validateAvatarPwd } from "src/store/slices/avatarSlice";
import { COLOR, IMAGE, SKYBOX } from "src/pages/Avatar/types/avatar.type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView } from "@fortawesome/free-solid-svg-icons";
import { updateCamera } from "src/common/Babylon/babylonUtils";
import { Engine, Scene } from "babylonjs";
import { handleInitErrorMessage } from "src/store/slices/settingsSlice";
import { initBabylon } from "src/common/Babylon/initBabylon";
import { Humanoid } from "src/common/Babylon/Humanoid";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import PasswordField from "src/components/Form/PasswordField";
import { Spinner } from "react-bootstrap";
import { FULFILLED, GET_AVATAR_UPDATE_TIME, INIT_MESSAGE } from "src/constants/constants";
import { handleThreadId, initMessageHandler, toggleLimitExceeds } from "src/store/slices/conversationSlice";
import BannerLayout from "src/layout/BannerLayout";
import useAvatarRefs from "./RefContext/useAvatarRefs";
import StopSpeakingButton from "./StopSpeakingButton";
import { AvatarPasswordData } from "./types/avatarCanvas.type";
import Banner2 from 'src/assets/images/banner-2.svg'

const AvatarCanvas = () => {
  const dispatch = useAppDispatch();
  let { tenantCode, avatarCode } = useParams();

  const { stopSpeaking, setHumanoidRef, destroyHumanoidRef } = useAvatarRefs();

  const sceneRef = useRef<Scene | null>(null);
  const engineRef = useRef<Engine | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // const { initErrorMessage } = useSelector((state: RootState) => state.setting);
  const { avatarData, isAvatarValidated, isLoading: avatarPswdLoading, avatarName } = useSelector((state: RootState) => state.avatar);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showAvatarLoader, setShowAvatarLoader] = useState<boolean>(false);
  const [didInit, setDidInit] = useState<boolean>(false);
  const [canvasbg, setCanvasbg] = useState<any>({});

  const initialValues: AvatarPasswordData = { password: "" };
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
  });

  const handleAvatarLoading = (isLoading: boolean) => {
    setShowAvatarLoader(isLoading);
  };

  const setEngineRef = useCallback((engine: Engine) => {
    engineRef.current = engine;
  }, []);

  const loadAvatar = async (payload: any) => {
    dispatch(setAvatarDataHandler(payload));
    const assistantId = payload.openAiAstId
    await dispatch(initMessageHandler(assistantId)).then((res: any) => {
      if (res.type === INIT_MESSAGE + FULFILLED) {
        dispatch(handleThreadId(res.payload.data))
      }
    });

    const { backgroundOption, background } = payload;
    const bg =
      backgroundOption === SKYBOX
        ? {}
        : backgroundOption === COLOR
          ? { background: background }
          : backgroundOption === IMAGE
            ? { backgroundImage: `url(${payload.background})` }
            : {};

    setCanvasbg(bg);

    try {
      if (canvasRef.current) {
        const cameraConfig = payload.config.cameraConfig;
        const skyboxInput =
          backgroundOption === SKYBOX ? background : null;

        sceneRef.current?.dispose()
        engineRef.current?.dispose()
        destroyHumanoidRef()

        sceneRef.current = initBabylon(
          handleAvatarLoading,
          cameraConfig,
          canvasRef.current,
          skyboxInput,
          setEngineRef
        );

        let newHumanoid = new Humanoid(sceneRef.current, payload, () => {
          setDidInit(true);
          setShowAvatarLoader(false);
        });

        setHumanoidRef(newHumanoid);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setShowAvatarLoader(false);
        dispatch(handleInitErrorMessage(error.message));
      }
    }
  };

  const handleSubmit = async (formValue: AvatarPasswordData) => {
    setShowLoader(true);
    const validateData = {
      tenantCode,
      avatarCode,
      password: formValue.password,
    };
    await dispatch(validateAvatarPwd(validateData));
    setShowLoader(false);
  };

  useEffect(() => {
    if (didInit) {
      // console.warn(
      //   "Warning - tried to initialize twice. Will skip 2nd initialization."
      // );
      return;
    }
    if (tenantCode && avatarCode) {
      const reqData = { tenantCode, avatarCode };
      if (avatarData?.code === avatarCode && isAvatarValidated) {
        dispatch(getAvatarUpdateTimeHandler(reqData)).then((res: any) => {
          if (res.type === GET_AVATAR_UPDATE_TIME + FULFILLED) {
            if (avatarData?.updatedAt === res.payload.data.updatedAt) {
              loadAvatar(avatarData);
            } else {
              dispatch(resetAvatarDataHandler());
            }
          }
        });
      } else {
        dispatch(checkForAvatarPwdHandler(reqData))
      }
    }
  }, [didInit, tenantCode, avatarCode, avatarData, isAvatarValidated]);

  // Remove the thread-id when user leave the conversation.
  useEffect(() => {
    return () => {
      dispatch(handleThreadId(null));
      dispatch(toggleLimitExceeds(false));
      sceneRef.current?.dispose()
      engineRef.current?.dispose()
      destroyHumanoidRef()
      stopSpeaking()
    }
  }, []);


  // if (initErrorMessage?.includes("WebGL not supported")) {
  //   return <WebGLNotSupported />;
  // }

  return (
    <>
      <div className="canvas-wrapper">
        {(showAvatarLoader || avatarPswdLoading) && (
          <div className="canvas-loader">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div
          className={`canvas-parent ${avatarData?.backgroundOption === IMAGE ? "canvas-image" : ""}`}
          style={canvasbg}
        >
          <canvas ref={canvasRef} className="canvas-element" />
          {!showAvatarLoader && sceneRef.current && (
            <>
              <Chat />
              {avatarData?.config?.cameraConfig &&
                <div className="reset-view-btn">
                  <div className="hstack gap-2">
                    <button
                      className="btn bg-primary-subtle d-flex align-items-center justify-content-center gap-2 shadow-sm"
                      onClick={() =>
                        updateCamera(avatarData?.config?.cameraConfig, sceneRef.current)
                      }
                    >
                      <span className="d-none d-md-block">Reset view</span>
                      <span className="d-md-none">
                        <FontAwesomeIcon icon={faStreetView} />
                      </span>
                    </button>
                    <StopSpeakingButton />
                  </div>
                </div>
              }
            </>
          )}
        </div>
      </div>

      {(!isAvatarValidated && !avatarPswdLoading) && (
        <>
          {(tenantCode && avatarCode) ? (
            <div className="position-absolute top-0 end-0 bottom-0 start-0">
              <BannerLayout title='Avatars' bannerImg={Banner2} color="primary">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form className="vstack gap-2">
                    <div className="fs-3 fw-semibold mt-4 mb-3 text-white">
                      {avatarName}
                    </div>
                    <PasswordField
                      label={`Enter password to access further`}
                      name="password"
                      autoComplete="new-password"
                      color="primary"
                    />
                    <small className="mb-3 px-1">
                      Password will be remembered for easy access next time
                    </small>
                    <div className="ms-auto">
                      <button
                        className="btn btn-primary hstack justify-content-center gap-3"
                        type="submit"
                        disabled={showLoader}
                      >
                        Submit
                        {showLoader && <Spinner size="sm" />}
                      </button>
                    </div>
                  </Form>
                </Formik>
              </BannerLayout>
            </div>
          ) : (
            <Navigate to={"/"} />
          )}
        </>
      )}
    </>
  );
};

export default AvatarCanvas;
