import ChatMessages from './ChatMessages'
import { ChatTextArea } from './ChatTextArea';
import { ChatErrors } from './ChatErrors';
import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
// import { Alert } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
// import { RootState } from 'src/store/store';
// import { useTranslation } from 'react-i18next';

interface ChatProps { };

const Chat: FC<ChatProps> = () => {
    // const { t } = useTranslation('common');
    // const { limitExceeds } = useSelector((state: RootState) => state.conversation);

    const [expandView, setExpandView] = useState<boolean>(false)

    return (
        <div className={`chat-wrapper ${expandView ? 'expandView' : ''}`}>
            <button className='btn btn-success d-md-none expand-view-btn' onClick={() => setExpandView(!expandView)}>
                <FontAwesomeIcon icon={faChevronUp} />
            </button>
            <ChatMessages />
            {/* {
                limitExceeds && (
                    <Alert variant="secondary" onClose={() => null} dismissible>
                        <Alert.Heading className='h5'>{t('avatar.questionLimitTitle')}</Alert.Heading>
                        <p>
                            {t('avatar.questionLimitDescription')}
                        </p>
                    </Alert>
                )
            } */}
            <ChatTextArea />
            <ChatErrors />
        </div>
    )
}

export default Chat