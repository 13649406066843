import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'src/components/UI/Loader';
import { FORGOT_PASSWORD, RESET_PASSWORD, SIGNIN } from 'src/constants/constants';
import { IRoute } from 'src/types/routes.type'
import AvatarCanvasWithContext from 'src/pages/AvatarCanvas/AvatarCanvasWithContext'; // DON'T IMPORT IT USING LAZY LOADING


// Pages
const Signin = lazy(() => import('../pages/Auth/Signin'));
const ForgotPassword = lazy(() => import('../pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Auth/ResetPassword'));

const routes: IRoute[] = [
  {
    key: 'sign-in',
    path: SIGNIN,
    element: <Signin />
  },
  {
    key: 'forgot-password',
    path: FORGOT_PASSWORD,
    element: <ForgotPassword />
  },
  {
    key: 'reset-password',
    path: RESET_PASSWORD + '/:token',
    element: <ResetPassword />
  },
  {
    key: 'avatar-canvas',
    path: '/bot/:tenantCode/:avatarCode',
    element: <AvatarCanvasWithContext />
  },
  {
    key: 'not-found',
    path: '*',
    element: <Navigate to={SIGNIN} />
  }
]

const PublicRoutes = () => {

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {
          routes?.map((route, i) => <Route key={`pbl-route-${route?.key}-${i}`} path={route?.path} element={route.element} />)
        }
      </Routes>
    </Suspense>
  )
}

export default PublicRoutes
