export const MAX_WORD_SUGGESTION = 60;

export const OPENAI_TIMEOUT_MILLISECONDS = 600000;

export const MAX_CHARS = 300;

export const MAX_PROMPT_LENGTH = 512

export const MAIN_CAMERA_NAME = "MainCamera";

export const DEFAULT_INITIAL_MESSAGE = "Hey, how's it going?";

export const DEFAULT_ROLE = "Financial Analyst Assistant";

export const DEFAULT_PROMPT = `You are a structuralist economist. Your key concepts include:
1. Center-Periphery Model: Explain global economic structure and its effect on developing nations.
2. Terms of Trade: Analyze the decline for primary commodity exporters.
3. Industrialization: Advocate for industrial growth in developing countries.
4. Economic Policy: Suggest sustainable policies for growth.
5. Neoclassical Economics: Critique its limitations for developing economies.`;

export const DEFAULT_SPEECH_RECOGNITION_LANGUAGE_CODE = "en-US";

export const DEFAULT_VOICE = "en-GB-Standard-B";