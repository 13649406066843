import { VoiceCode } from "src/constants/voices"
import { ENUM_BACKGROUND_OPTION } from "src/types/enum"

export type IAvatarListing = {
    uuid: string
    name: string
    role: string
    createdByAdmin: string
    tenantCode: string
    avatarCode: string
}

export type MorphTargets = {
    leftEyeClose: string | null
    rightEyeClose: string | null
    mouthMovement: string | null
}

export type CameraConfig = {
    alpha: string
    beta: string
    radius: number
    positionX: number
    positionY: number
    positionZ: number
}

export type PositionOffset = {
    positionOffsetX: number
    positionOffsetY: number
    positionOffsetZ: number
}

export type Config = {
    cameraConfig: CameraConfig,
    positionOffset: PositionOffset
}

export type Animations = {
    idleAnimations: string[]
    talkingAnimations: string[]
}

export type AvatarData = {
    animations: Animations
    avatarStorageLink: string
    background: string
    backgroundOption: ENUM_BACKGROUND_OPTION
    baseAvatarId: null
    code: string
    comment: string | null
    config: Config
    createdAt: string
    createdBy: string | null
    deletedAt: string | null
    deletedBy: string | null
    faceMeshName: string
    initialAnimation: string
    initialMessage: string
    morphTargets: MorphTargets
    name: string
    openAiAstId: string
    openAiVectorStoreId: string | null
    prompt: string
    pwd: string
    role: string
    tenantId: string | null
    updatedAt: string
    updatedBy: string | null
    uuid: string
    voice: string
}

export type FileUpload = {
    fileName: string;
    contentType: string;
};

export type AvatarRequestData = {
    avatarStorageLink?: string | null;
    selectedAssistantFiles?: string[];
    name?: string;
    voice?: string;
    faceMeshName?: string;
    morphTargets?: MorphTargets;
    initialAnimation?: string;
    config?: Config;
    animations?: Animations;
    role?: string;
    initialMessage?: string;
    prompt?: string;
    pwd?: string | null;
    backgroundOption?: string;
    background?: string;
    baseAvatarId?: string | null;
    questionLimit?: number;
    filesToDelete?: string[];
};

export type AssistantFiles = File[] | undefined;

export type AstFileStorageLinks = {
    url: string;
    name: string;
    id: string;
};

export type CreateAvatar = {
    avatarFile: File | undefined;
    avatarStorageLink: string | null; //we send file name in post req and recieve file url in get req in avatarStorageLink and astFileStorageLinks[]

    assistantFiles: AssistantFiles;
    selectedAssistantFiles: string[];
    astFileStorageLinks: AstFileStorageLinks[];

    idleAnimations: string[];
    initialAnimation: string;

    talkingAnimations: string[];
    // selectedTalkingAnimations: any

    meshNames: string[]; //for selecting faceMeshName
    faceMeshName: string;

    morphTargets: string[];
    leftEyeClose: string;
    rightEyeClose: string;
    mouthMovement: string;

    ////camera config
    alpha: string;
    beta: string;
    radius: number;
    //target
    positionX: number;
    positionY: number;
    positionZ: number;

    //positionOffset
    positionOffsetX: number;
    positionOffsetY: number;
    positionOffsetZ: number;

    name: string;
    voice: VoiceCode;
    initialMessage: string;
    role: string;
    prompt: string;

    pwd: string | null;
    backgroundOption: ENUM_BACKGROUND_OPTION;
    imageInput: string;
    colorInput: string;
    skyboxInput: string;

    baseAvatarId: string | null;
    questionLimit: number;
};


///////////////////constants//////////////
export const IMAGE = ENUM_BACKGROUND_OPTION.IMAGE;
export const COLOR = ENUM_BACKGROUND_OPTION.COLOR;
export const SKYBOX = ENUM_BACKGROUND_OPTION.SKYBOX;