import { faCircleNotch, faMicrophone, faPaperPlane, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { MAX_CHARS, OPENAI_TIMEOUT_MILLISECONDS } from "src/config/modelConfig";
import {
  handleAbortController,
  handleCharCount,
  handleChatErrorMessage,
  handleIsLoadingMessage,
  handleNewMessage,
  sendMessageHandler,
} from "src/store/slices/conversationSlice";
import { RootState, useAppDispatch } from "src/store/store";
import { getProviderByVoice } from "src/constants/voices";
import useAvatarRefs from "../RefContext/useAvatarRefs";
import { CHAT_BY_USER, IChatMessage, ISendMessageData, IVoiceData } from "src/pages/Conversation/types/conversation.type";

export const ChatTextArea = () => {
  const {
    transcript,
    listening,
    isMicrophoneAvailable,
    browserSupportsSpeechRecognition,
    resetTranscript
  } = useSpeechRecognition();

  const dispatch = useAppDispatch();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { stopSpeaking } = useAvatarRefs();
  const { avatarData } = useSelector((state: RootState) => state.avatar);
  const {
    messages,
    isLoadingMessage,
    abortController,
    threadId,
    limitExceeds,
  } = useSelector((state: RootState) => state.conversation);

  const voice = avatarData.voice;
  const voiceData: IVoiceData = {
    voice,
    voiceProvider: getProviderByVoice(voice)
  };

  const hasText = textAreaRef.current
    ? textAreaRef.current.value.length > 0
    : false;

  const listen = async (): Promise<void> => {
    await SpeechRecognition.startListening({
      continuous: true
    });
  };

  const validateMessageLength = (newMessage: IChatMessage): boolean => {
    if (newMessage.content.length > MAX_CHARS) {
      dispatch(
        handleChatErrorMessage(
          `Please enter a message with ${MAX_CHARS} characters or less.`
        )
      );
      return false;
    }
    return true;
  };

  const sendPostRequestWithMultipleMessages = async (
    messagesData: IChatMessage[]
  ) => {

    if (isLoadingMessage && abortController) {
      // console.log("Aborting previous request.");
      abortController.abort("New request sent - aborting previous.");
    }


    const newAbortController = new AbortController();
    const timeoutId = setTimeout(() => {
      // console.log("Request took too long. Aborting.");
      newAbortController.abort("Request took too long. Aborting.");
    }, OPENAI_TIMEOUT_MILLISECONDS);


    try {
      dispatch(handleIsLoadingMessage(true));
      dispatch(handleAbortController(newAbortController));

      const data: ISendMessageData = {
        messagesData,
        voiceData,
        assistantId: avatarData.openAiAstId,
        threadId,
      };

      await dispatch(sendMessageHandler(data))
    } finally {
      clearTimeout(timeoutId);
      dispatch(handleIsLoadingMessage(false));
      setTimeout(() => {
        if (textAreaRef?.current) {
          textAreaRef.current.focus();
        }
      }, 100)
    }
  };

  const sendChatMessage = async () => {
    if (listening) stopSpeaking();

    dispatch(handleChatErrorMessage(null));
    if (isLoadingMessage || !textAreaRef?.current?.value) return;

    const content = textAreaRef.current.value;
    const newMessage: IChatMessage = { content, role: CHAT_BY_USER };

    if (!validateMessageLength(newMessage)) return;

    textAreaRef.current.value = "";
    resetTranscript();

    dispatch(handleNewMessage(newMessage));
        
    const messagesToSendToBackend = threadId
      ? [newMessage]
      : [...messages, newMessage];

    await sendPostRequestWithMultipleMessages(messagesToSendToBackend);
  };


  const toggleSpeaking = () => {
    if (listening) {
      stopSpeaking()
    } else {
      listen()
    }
  }

  // The following useEffect is used to scroll to the bottom of the text area.
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [textAreaRef]);

  // The following useEffect updates the text area value when the speech recognition transcript is updated.
  useEffect(() => {
    if (textAreaRef.current && transcript) {
      textAreaRef.current.value = transcript;
      dispatch(handleCharCount(transcript));
    }
  }, [transcript]);

  return (
    <div className="position-relative hstack w-100">
      <textarea
        autoFocus
        className="custom-form-control"
        style={{ paddingRight: '4rem' }}
        rows={3}
        ref={textAreaRef}
        placeholder={
          isLoadingMessage ? "Loading message..." : "Type message here"
        }
        disabled={limitExceeds || isLoadingMessage || listening}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendChatMessage();
          }
        }}
        onChange={(e) => {
          dispatch(handleCharCount(e.target.value));
        }}
      />
      <div className="position-absolute me-3 end-0">
        <div className="hstack gap-3">
          <button
            className="btn btn-primary d-flex align-items-center justify-content-center size-42"
            disabled={limitExceeds || isLoadingMessage}
            onClick={() => {
              if (hasText) {
                sendChatMessage();
              } else if (!isMicrophoneAvailable) {
                window.alert("Microphone is not available.");
              } else if (!browserSupportsSpeechRecognition) {
                window.alert(
                  "Your browser does not support speech recognition."
                );
              } else {
                toggleSpeaking()
              }
            }}
          >

            {isLoadingMessage ? (
              <FontAwesomeIcon icon={faCircleNotch} spin />
            ) : (
              <FontAwesomeIcon icon={listening ? faStop : hasText ? faPaperPlane : faMicrophone} />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};