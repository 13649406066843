import { FC, memo, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "src/store/store";
import BotMessage from "./Message/BotMessage";
import UserMessage from "./Message/UserMessage";
import { handleNewMessage, handleThreadId, resetMessageHandler } from "src/store/slices/conversationSlice";
import MessageWrapper from "./Message/MessageWrapper";
import { SOCKET_URL } from "src/common/commonUtils";
import { io } from "socket.io-client";
import useAvatarRefs from "../RefContext/useAvatarRefs";
import { ASSISTANT, BotResponse, IChatMessage } from "src/pages/Conversation/types/conversation.type";

interface ChatMessagesProps { };
const ChatMessages: FC<ChatMessagesProps> = memo(() => {
    const dispatch = useAppDispatch()

    const endRef = useRef<HTMLDivElement>(null);
    const { messages, threadId } = useSelector((state: RootState) => state.conversation)
    const { avatarData, isAvatarValidated } = useSelector((state: RootState) => state.avatar)
    const { isVolumeOn } = useSelector((state: RootState) => state.setting);
    const [currentMessage, setCurrentMessage] = useState<string>("");
    // const [socket, setSocket] = useState<Socket | null>(null);
    const { humanoidRef, currentSoundRef, setCurrentSoundRef, setIsSpeaking } = useAvatarRefs();

    const handleBotResponse = async (response: BotResponse) => {
        const audioContent = response?.audio;
        if (!audioContent) return;


        dispatch(handleThreadId(response.threadId));
        const textContent = response.text;

        const newMessage: IChatMessage = { content: textContent, role: ASSISTANT };
        dispatch(handleNewMessage(newMessage));


        const audio = new Audio(`data:audio/mpeg;base64,${audioContent}`);

        currentSoundRef.current?.pause();
        humanoidRef.current?.talkAnimationEnd("Received new audio");

        if (!isVolumeOn) {
            audio.volume = 0;
        }

        setIsSpeaking(true);

        try {
            await audio.play();
            setCurrentSoundRef(audio)
            humanoidRef.current?.talkAnimationStart();
        } catch (error) {
            console.error("Error playing audio:", error);
        }

        audio.addEventListener("ended", onAudioEnd);
    };

    const onAudioEnd = () => {
        humanoidRef.current?.talkAnimationEnd("onAudioEnd");
        setIsSpeaking(false);
    };

    useEffect(() => {
        if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: "auto" });
        }
    }, [messages, currentMessage]);

    useEffect(() => {
        if (avatarData && isAvatarValidated) {
            dispatch(resetMessageHandler())
            const newMessage: IChatMessage = { content: avatarData.initialMessage, role: ASSISTANT }
            dispatch(handleNewMessage(newMessage))
        }
    }, [avatarData, isAvatarValidated, dispatch])

    useEffect(() => {
        if (threadId) {
            const newSocket = io(SOCKET_URL, {
                reconnection: true, // Enable reconnection
                reconnectionAttempts: Infinity, // Attempt to reconnect indefinitely
                reconnectionDelay: 1000, // Delay before the first reconnection attempt
                reconnectionDelayMax: 5000, // Max delay between reconnection attempts
                randomizationFactor: 0.5, // Randomizes the reconnection delay
                query: { threadId: threadId }
            });
            // setSocket(newSocket);

            newSocket.on("newMessageWord", (word: string) => {
                setCurrentMessage((prev) => (prev ? `${prev} ${word}` : word)); // Append word to the current message
            });

            newSocket.on("messageWordCompleted", (response: BotResponse) => {
                setCurrentMessage('');
                handleBotResponse(response)
            });

            // Handle reconnection events (optional)
            newSocket.on('reconnect', () => {
                console.log('Reconnected to WebSocket');
            });

            newSocket.on('disconnect', () => {
                console.log('Disconnected from WebSocket');
            });

            return () => {
                newSocket.disconnect()
            };
        }

    }, [threadId]);

    return (
        <div className="chat-messages-wrapper">
            {messages?.map((message: IChatMessage, index) => {
                if (message.role === ASSISTANT) {
                    return <BotMessage message={message} key={message.role + index} />;
                } else {
                    return <UserMessage message={message} key={message.role + index} />;
                }
            })}
            {currentMessage && (
                <MessageWrapper name={avatarData.name}>
                    {currentMessage}
                </MessageWrapper>
            )}
            <div ref={endRef} /> {/* div for auto scrolling purposes */}
        </div>
    );
});

export default ChatMessages