import { Formik, Form } from "formik";
import { logoutHandler } from "src/store/slices/authSlice";
import * as Yup from "yup";
import PasswordField from "../../components/Form/PasswordField";
import { useAppDispatch } from "src/store/store";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { CHANGE_PASSWORD_IN, FULFILLED } from "src/constants/constants";
import { maxPassword, minPassword } from "src/common/commonUtils";
import { changePasswordInHandler } from "src/store/slices/userSlice";
import { IChangePasswordIn } from "./types/profile.type";

const ProfileChangePassword = () => {
    return (
        <>
            <div className='text-white fs-4 mb-4'>Change Password</div>
            <div className="row">
                <div className="col-lg-6">
                    <ChangePasswordForm />
                </div>
            </div>
        </>
    )
}

export default ProfileChangePassword


const ChangePasswordForm = () => {
    const dispatch = useAppDispatch();
    let { userId } = useParams()

    const [showLoader, setShowLoader] = useState<boolean>(false)

    //formik starts here
    const initialValues: IChangePasswordIn = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    };

    const validationSchema = Yup.object().shape({
        currentPassword: Yup.string()
            .required("This field is required").transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            .matches(
                /^(?!\s)(?!.*\s$).*$/,
                'Password cannot have leading or trailing spaces'
            ),
        newPassword: Yup.string()
            .required("This field is required").transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            // .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
            .matches(
                /^(?!\s)(?!.*\s$).*$/,
                'Password cannot have leading or trailing spaces'
            )
            .test(
                "len",
                `Password must be between ${minPassword} to ${maxPassword} characters`,
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= minPassword && trimmedValue.length <= maxPassword;
                }
            )
            .notOneOf([Yup.ref('currentPassword'), null], 'New password cannot be the same as current password'),
        confirmPassword: Yup.string()
            .required("This field is required").transform((value, originalValue) => {
                if (typeof originalValue === 'string') {
                    return originalValue.trim();
                }
                return value;
            })
            .strict(true)
            // .matches(/^[A-Za-z0-9#@$\s]+$/, "Only #, @ and $ special characters are allowed.")
            .matches(
                /^(?!\s)(?!.*\s$).*$/,
                'Password cannot have leading or trailing spaces'
            )
            .test(
                "len",
                `Password must be between ${minPassword} to ${maxPassword} characters`,
                (val: any) => {
                    const trimmedValue = val && val.toString().trim();
                    return trimmedValue && trimmedValue.length >= 6 && trimmedValue.length <= 20;
                }
            )
            .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    });
    //formik ends here


    //functions starts here
    const handleChangePassword = async (formValue: IChangePasswordIn) => {
        if (!userId) return
        setShowLoader(true)
        const { currentPassword, newPassword } = formValue
        await dispatch(changePasswordInHandler({ userId, currentPassword, newPassword })).then((res: any) => {
            if (res.type === CHANGE_PASSWORD_IN + FULFILLED) {
                setTimeout(() => {
                    dispatch(logoutHandler())
                    setShowLoader(false)
                }, 1500)
            }else{
                setShowLoader(false)
            }
        })
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleChangePassword}
        >
            <Form className='vstack gap-3'>
                <PasswordField name="currentPassword" label="Current Password" />
                <PasswordField name="newPassword" label="Password" />
                <PasswordField name="confirmPassword" label="Confirm Password" />
                <small className='text-muted d-block'>You will be redirected to login after changing password</small>
                <div className="ms-auto">
                    <button className='btn btn-primary hstack justify-content-center gap-3' type='submit' disabled={showLoader}>
                        Set new password
                        {showLoader && <Spinner size="sm" />}
                    </button>
                </div>
            </Form>
        </Formik>
    );
}