import { IChatMessage } from "src/pages/Conversation/types/conversation.type";
import MessageWrapper from "./MessageWrapper";

type UserMessageProps = {
    message: IChatMessage;
    userName?: string;
};
const UserMessage = ({ message, userName = 'You' }: UserMessageProps) => {
    const lines = message.content.split("\n");

    return (
        <MessageWrapper name={userName} classes={`${message?.role === 'user' ? 'd-flex justify-content-end' : ''}`}>
            {lines.map((line, lineIndex) => {
                return <p key={lineIndex} className="message-content">{line === "" ? <br /> : line}</p>;
            })}
        </MessageWrapper>
    );
};
export default UserMessage