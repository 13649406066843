import { useSelector } from "react-redux";
import { RootState } from "src/store/store";

export const ChatErrors = () => {
    const { errorMessage } = useSelector((state: RootState) => state.conversation);

    if (errorMessage) {
        return (
            <div className="text-danger mt-3 mb-1 text-center">
                {errorMessage}
            </div>
        )
    }

    return null;
};
