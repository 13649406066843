import { useCallback } from "react";
import { useSelector } from "react-redux"
import { RootState } from "src/store/store";
import { PermissionType, ValidatePermissionsFunOptions } from "src/types/permission.type";

export const usePermissionGuard = () => {
    // Redux State
    const { user, isAdmin, isUser } = useSelector((state: RootState) => state.auth);
    const isOwner = user?.user?.roles[0]?.name === "OWNER";

    const validatePermissions = useCallback((
        permissions: PermissionType[],
        options?: ValidatePermissionsFunOptions
    ) => {
        let isValid = true; // By default is true for admin-user.
        let mainCheckPoint = !isAdmin;


        switch (options?.forceCheckIs) {
            /**
             * Strically check for the user. If logged-In user is admin then the given permissions are not validate.
             */
            case 'USER':
                mainCheckPoint = isUser;
                isValid = false;
                break;
            /**
             * Strically check for the tenant's owner user. If logged-In user is admin or not a tenan's owner then the given permissions are not validate.
             */
            case 'OWNER':
                mainCheckPoint = isOwner;
                isValid = false;
                break;
            case 'ADMIN':
                mainCheckPoint = false;
                isValid = false;
                break;
        }

        if (
            mainCheckPoint
            && (user?.permissions as PermissionType[])?.length > 0
            && permissions?.length > 0
        ) {
            const allPermissions = [...user?.permissions] as PermissionType[];

            for (const permission of permissions) {
                if (options?.condition === 'OR') {
                    if (allPermissions?.indexOf(permission) !== -1) {
                        isValid = true;
                        break;
                    }
                    else {
                        isValid = false;
                        continue;
                    }
                }
                else if (options?.condition === 'AND') {
                    // No required 
                }
                else {
                    if (allPermissions?.indexOf(permission) === -1) {
                        isValid = false;
                        break;
                    }
                    else {
                        isValid = true;
                    }
                }
            }
        }
        else {
            switch (options?.forceCheckIs) {
                case 'USER':
                    isValid = isUser;
                    break;
                case 'OWNER':
                    isValid = isOwner
                    break;
                case 'ADMIN':
                    isValid = isAdmin;
                    break;
                default:
                    isValid = true;
                    break;
            }
        }
        return isValid;
    }, [user?.permissions, isAdmin, isUser, isOwner]);


    return {
        validatePermissions
    }
}