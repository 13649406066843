import { FC, ReactNode } from 'react'
interface BannerLayoutProps {
    title: string
    bannerImg: string
    children: ReactNode
    color?: string
}
const BannerLayout: FC<BannerLayoutProps> = ({ title, bannerImg, children, color = 'white' }) => {
    return (
        <div className='container-fluid vh-100'>
            <div className="row h-100">
                <div className={`col-lg-4 position-relative overflow-hidden`}>
                    <img src={bannerImg} className='banner-image' />
                </div>
                <div className="col-lg-8">
                    <div className='vstack justify-content-center h-100 px-4 px-lg-5'>
                        <div className="row justify-content-center justify-content-lg-start mb-5">
                            <div className="col-md-6 col-lg-8 col-xl-6">
                                <div className='vstack gap-3'>
                                    <div className={`text-${color} fs-2`}>{title}</div>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BannerLayout