import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import MessageWrapper from "./MessageWrapper";
import { IChatMessage } from "src/pages/Conversation/types/conversation.type";

type BotMessageProps = {
    message: IChatMessage;
};

const BotMessage = ({ message }: BotMessageProps) => {
    const { avatarData } = useSelector((state: RootState) => state.avatar)
    const lines = message.content.split("\n");
    return (
        <MessageWrapper name={avatarData.name}>
            {lines.map((line, lineIndex) => {
                return <p key={lineIndex} className="message-content">{line === "" ? <br /> : line}</p>;
            })}
            {/* <TypewriterComponent
                onInit={(typewriter) => {
                    typewriter.typeString(message.content)
                        .callFunction((state) => {
                            state.elements.cursor.remove();
                        })
                        .start();
                }}
                options={{
                    delay: 50
                }}
            /> */}
        </MessageWrapper>
    );
};
export default BotMessage