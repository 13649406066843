type Gender = "MALE" | "FEMALE";
type VoiceProvider = "Google Cloud" | "OpenAI" | "ElevenLabs";
type allVoiceOption = {
    value: string
    language: string
    gender: Gender
    provider: VoiceProvider
    label: string
}

export const allVoiceOptions: allVoiceOption[] = [
    { value: "en-GB-Standard-A", language: "English-GB", gender: "FEMALE", provider: "Google Cloud", label: "en-GB-Standard-A (FEMALE, English-GB, Google Cloud)" },
    { value: "en-GB-Standard-B", language: "English-GB", gender: "MALE", provider: "Google Cloud", label: "en-GB-Standard-B (MALE, English-GB, Google Cloud)" },
    { value: "en-GB-Standard-C", language: "English-GB", gender: "FEMALE", provider: "Google Cloud", label: "en-GB-Standard-C (FEMALE, English-GB, Google Cloud)" },
    { value: "en-GB-Standard-D", language: "English-GB", gender: "MALE", provider: "Google Cloud", label: "en-GB-Standard-D (MALE, English-GB, Google Cloud)" },
    { value: "en-GB-Standard-F", language: "English-GB", gender: "FEMALE", provider: "Google Cloud", label: "en-GB-Standard-F (FEMALE, English-GB, Google Cloud)" },
    { value: "en-US-Neural2-A", language: "English-US", gender: "MALE", provider: "Google Cloud", label: "en-US-Neural2-A (MALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-C", language: "English-US", gender: "FEMALE", provider: "Google Cloud", label: "en-US-Neural2-C (FEMALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-D", language: "English-US", gender: "MALE", provider: "Google Cloud", label: "en-US-Neural2-D (MALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-E", language: "English-US", gender: "FEMALE", provider: "Google Cloud", label: "en-US-Neural2-E (FEMALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-F", language: "English-US", gender: "FEMALE", provider: "Google Cloud", label: "en-US-Neural2-F (FEMALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-G", language: "English-US", gender: "FEMALE", provider: "Google Cloud", label: "en-US-Neural2-G (FEMALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-H", language: "English-US", gender: "FEMALE", provider: "Google Cloud", label: "en-US-Neural2-H (FEMALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-I", language: "English-US", gender: "MALE", provider: "Google Cloud", label: "en-US-Neural2-I (MALE, English-US, Google Cloud)" },
    { value: "en-US-Neural2-J", language: "English-US", gender: "MALE", provider: "Google Cloud", label: "en-US-Neural2-J (MALE, English-US, Google Cloud)" },
    { value: "af-ZA-Standard-A", language: "Afrikaans-ZA", gender: "FEMALE", provider: "Google Cloud", label: "af-ZA-Standard-A (FEMALE, Afrikaans-ZA, Google Cloud)" },
    { value: "ar-XA-Standard-A", language: "Arabic-XA", gender: "FEMALE", provider: "Google Cloud", label: "ar-XA-Standard-A (FEMALE, Arabic-XA, Google Cloud)" },
    { value: "ar-XA-Standard-B", language: "Arabic-XA", gender: "MALE", provider: "Google Cloud", label: "ar-XA-Standard-B (MALE, Arabic-XA, Google Cloud)" },
    { value: "ar-XA-Standard-C", language: "Arabic-XA", gender: "MALE", provider: "Google Cloud", label: "ar-XA-Standard-C (MALE, Arabic-XA, Google Cloud)" },
    { value: "ar-XA-Standard-D", language: "Arabic-XA", gender: "FEMALE", provider: "Google Cloud", label: "ar-XA-Standard-D (FEMALE, Arabic-XA, Google Cloud)" },
    { value: "eu-ES-Standard-A", language: "Basque-ES", gender: "FEMALE", provider: "Google Cloud", label: "eu-ES-Standard-A (FEMALE, Basque-ES, Google Cloud)" },
    { value: "bn-IN-Standard-A", language: "Bengali-IN", gender: "FEMALE", provider: "Google Cloud", label: "bn-IN-Standard-A (FEMALE, Bengali-IN, Google Cloud)" },
    { value: "bn-IN-Standard-B", language: "Bengali-IN", gender: "MALE", provider: "Google Cloud", label: "bn-IN-Standard-B (MALE, Bengali-IN, Google Cloud)" },
    { value: "bn-IN-Standard-C", language: "Bengali-IN", gender: "FEMALE", provider: "Google Cloud", label: "bn-IN-Standard-C (FEMALE, Bengali-IN, Google Cloud)" },
    { value: "bn-IN-Standard-D", language: "Bengali-IN", gender: "MALE", provider: "Google Cloud", label: "bn-IN-Standard-D (MALE, Bengali-IN, Google Cloud)" },
    { value: "bg-BG-Standard-A", language: "Bulgarian-BG", gender: "FEMALE", provider: "Google Cloud", label: "bg-BG-Standard-A (FEMALE, Bulgarian-BG, Google Cloud)" },
    { value: "yue-HK-Standard-A", language: "Cantonese-HK", gender: "FEMALE", provider: "Google Cloud", label: "yue-HK-Standard-A (FEMALE, Cantonese-HK, Google Cloud)" },
    { value: "yue-HK-Standard-B", language: "Cantonese-HK", gender: "MALE", provider: "Google Cloud", label: "yue-HK-Standard-B (MALE, Cantonese-HK, Google Cloud)" },
    { value: "yue-HK-Standard-C", language: "Cantonese-HK", gender: "FEMALE", provider: "Google Cloud", label: "yue-HK-Standard-C (FEMALE, Cantonese-HK, Google Cloud)" },
    { value: "yue-HK-Standard-D", language: "Cantonese-HK", gender: "MALE", provider: "Google Cloud", label: "yue-HK-Standard-D (MALE, Cantonese-HK, Google Cloud)" },
    { value: "cs-CZ-Standard-A", language: "Czech-CZ", gender: "FEMALE", provider: "Google Cloud", label: "cs-CZ-Standard-A (FEMALE, Czech-CZ, Google Cloud)" },
    { value: "da-DK-Standard-A", language: "Danish-DK", gender: "FEMALE", provider: "Google Cloud", label: "da-DK-Standard-A (FEMALE, Danish-DK, Google Cloud)" },
    { value: "da-DK-Standard-C", language: "Danish-DK", gender: "MALE", provider: "Google Cloud", label: "da-DK-Standard-C (MALE, Danish-DK, Google Cloud)" },
    { value: "da-DK-Standard-D", language: "Danish-DK", gender: "FEMALE", provider: "Google Cloud", label: "da-DK-Standard-D (FEMALE, Danish-DK, Google Cloud)" },
    { value: "da-DK-Standard-E", language: "Danish-DK", gender: "FEMALE", provider: "Google Cloud", label: "da-DK-Standard-E (FEMALE, Danish-DK, Google Cloud)" },
    { value: "nl-BE-Standard-A", language: "Dutch-BE", gender: "FEMALE", provider: "Google Cloud", label: "nl-BE-Standard-A (FEMALE, Dutch-BE, Google Cloud)" },
    { value: "nl-BE-Standard-B", language: "Dutch-BE", gender: "MALE", provider: "Google Cloud", label: "nl-BE-Standard-B (MALE, Dutch-BE, Google Cloud)" },
    { value: "nl-NL-Standard-A", language: "Dutch-NL", gender: "FEMALE", provider: "Google Cloud", label: "nl-NL-Standard-A (FEMALE, Dutch-NL, Google Cloud)" },
    { value: "nl-NL-Standard-B", language: "Dutch-NL", gender: "MALE", provider: "Google Cloud", label: "nl-NL-Standard-B (MALE, Dutch-NL, Google Cloud)" },
    { value: "nl-NL-Standard-C", language: "Dutch-NL", gender: "MALE", provider: "Google Cloud", label: "nl-NL-Standard-C (MALE, Dutch-NL, Google Cloud)" },
    { value: "nl-NL-Standard-D", language: "Dutch-NL", gender: "FEMALE", provider: "Google Cloud", label: "nl-NL-Standard-D (FEMALE, Dutch-NL, Google Cloud)" },
    { value: "nl-NL-Standard-E", language: "Dutch-NL", gender: "FEMALE", provider: "Google Cloud", label: "nl-NL-Standard-E (FEMALE, Dutch-NL, Google Cloud)" },
    { value: "en-AU-Polyglot-1", language: "English-AU", gender: "MALE", provider: "Google Cloud", label: "en-AU-Polyglot-1 (MALE, English-AU, Google Cloud)" },
    { value: "fil-PH-Standard-A", language: "Filipino-PH", gender: "FEMALE", provider: "Google Cloud", label: "fil-PH-Standard-A (FEMALE, Filipino-PH, Google Cloud)" },
    { value: "fil-PH-Standard-B", language: "Filipino-PH", gender: "FEMALE", provider: "Google Cloud", label: "fil-PH-Standard-B (FEMALE, Filipino-PH, Google Cloud)" },
    { value: "fil-PH-Standard-C", language: "Filipino-PH", gender: "MALE", provider: "Google Cloud", label: "fil-PH-Standard-C (MALE, Filipino-PH, Google Cloud)" },
    { value: "fil-PH-Standard-D", language: "Filipino-PH", gender: "MALE", provider: "Google Cloud", label: "fil-PH-Standard-D (MALE, Filipino-PH, Google Cloud)" },
    { value: "fi-FI-Standard-A", language: "Finnish-FI", gender: "FEMALE", provider: "Google Cloud", label: "fi-FI-Standard-A (FEMALE, Finnish-FI, Google Cloud)" },
    { value: "fr-CA-Standard-A", language: "French-CA", gender: "FEMALE", provider: "Google Cloud", label: "fr-CA-Standard-A (FEMALE, French-CA, Google Cloud)" },
    { value: "fr-CA-Standard-B", language: "French-CA", gender: "MALE", provider: "Google Cloud", label: "fr-CA-Standard-B (MALE, French-CA, Google Cloud)" },
    { value: "fr-CA-Standard-C", language: "French-CA", gender: "FEMALE", provider: "Google Cloud", label: "fr-CA-Standard-C (FEMALE, French-CA, Google Cloud)" },
    { value: "fr-CA-Standard-D", language: "French-CA", gender: "MALE", provider: "Google Cloud", label: "fr-CA-Standard-D (MALE, French-CA, Google Cloud)" },
    { value: "fr-FR-Polyglot-1", language: "French-FR", gender: "MALE", provider: "Google Cloud", label: "fr-FR-Polyglot-1 (MALE, French-FR, Google Cloud)" },
    { value: "fr-FR-Standard-A", language: "French-FR", gender: "FEMALE", provider: "Google Cloud", label: "fr-FR-Standard-A (FEMALE, French-FR, Google Cloud)" },
    { value: "fr-FR-Standard-B", language: "French-FR", gender: "MALE", provider: "Google Cloud", label: "fr-FR-Standard-B (MALE, French-FR, Google Cloud)" },
    { value: "fr-FR-Standard-C", language: "French-FR", gender: "FEMALE", provider: "Google Cloud", label: "fr-FR-Standard-C (FEMALE, French-FR, Google Cloud)" },
    { value: "fr-FR-Standard-D", language: "French-FR", gender: "MALE", provider: "Google Cloud", label: "fr-FR-Standard-D (MALE, French-FR, Google Cloud)" },
    { value: "fr-FR-Standard-E", language: "French-FR", gender: "FEMALE", provider: "Google Cloud", label: "fr-FR-Standard-E (FEMALE, French-FR, Google Cloud)" },
    { value: "gl-ES-Standard-A", language: "Galician-ES", gender: "FEMALE", provider: "Google Cloud", label: "gl-ES-Standard-A (FEMALE, Galician-ES, Google Cloud)" },
    { value: "de-DE-Polyglot-1", language: "German-DE", gender: "MALE", provider: "Google Cloud", label: "de-DE-Polyglot-1 (MALE, German-DE, Google Cloud)" },
    { value: "de-DE-Standard-A", language: "German-DE", gender: "FEMALE", provider: "Google Cloud", label: "de-DE-Standard-A (FEMALE, German-DE, Google Cloud)" },
    { value: "de-DE-Standard-B", language: "German-DE", gender: "MALE", provider: "Google Cloud", label: "de-DE-Standard-B (MALE, German-DE, Google Cloud)" },
    { value: "de-DE-Standard-C", language: "German-DE", gender: "FEMALE", provider: "Google Cloud", label: "de-DE-Standard-C (FEMALE, German-DE, Google Cloud)" },
    { value: "de-DE-Standard-D", language: "German-DE", gender: "MALE", provider: "Google Cloud", label: "de-DE-Standard-D (MALE, German-DE, Google Cloud)" },
    { value: "de-DE-Standard-E", language: "German-DE", gender: "MALE", provider: "Google Cloud", label: "de-DE-Standard-E (MALE, German-DE, Google Cloud)" },
    { value: "de-DE-Standard-F", language: "German-DE", gender: "FEMALE", provider: "Google Cloud", label: "de-DE-Standard-F (FEMALE, German-DE, Google Cloud)" },
    { value: "el-GR-Standard-A", language: "Greek-GR", gender: "FEMALE", provider: "Google Cloud", label: "el-GR-Standard-A (FEMALE, Greek-GR, Google Cloud)" },
    { value: "gu-IN-Standard-A", language: "Gujarati-IN", gender: "FEMALE", provider: "Google Cloud", label: "gu-IN-Standard-A (FEMALE, Gujarati-IN, Google Cloud)" },
    { value: "gu-IN-Standard-B", language: "Gujarati-IN", gender: "MALE", provider: "Google Cloud", label: "gu-IN-Standard-B (MALE, Gujarati-IN, Google Cloud)" },
    { value: "he-IL-Standard-A", language: "Hebrew-IL", gender: "FEMALE", provider: "Google Cloud", label: "he-IL-Standard-A (FEMALE, Hebrew-IL, Google Cloud)" },
    { value: "he-IL-Standard-B", language: "Hebrew-IL", gender: "MALE", provider: "Google Cloud", label: "he-IL-Standard-B (MALE, Hebrew-IL, Google Cloud)" },
    { value: "he-IL-Standard-C", language: "Hebrew-IL", gender: "FEMALE", provider: "Google Cloud", label: "he-IL-Standard-C (FEMALE, Hebrew-IL, Google Cloud)" },
    { value: "he-IL-Standard-D", language: "Hebrew-IL", gender: "MALE", provider: "Google Cloud", label: "he-IL-Standard-D (MALE, Hebrew-IL, Google Cloud)" },
    { value: "hi-IN-Standard-A", language: "Hindi-IN", gender: "FEMALE", provider: "Google Cloud", label: "hi-IN-Standard-A (FEMALE, Hindi-IN, Google Cloud)" },
    { value: "hi-IN-Standard-B", language: "Hindi-IN", gender: "MALE", provider: "Google Cloud", label: "hi-IN-Standard-B (MALE, Hindi-IN, Google Cloud)" },
    { value: "hi-IN-Standard-C", language: "Hindi-IN", gender: "MALE", provider: "Google Cloud", label: "hi-IN-Standard-C (MALE, Hindi-IN, Google Cloud)" },
    { value: "hi-IN-Standard-D", language: "Hindi-IN", gender: "FEMALE", provider: "Google Cloud", label: "hi-IN-Standard-D (FEMALE, Hindi-IN, Google Cloud)" },
    { value: "hu-HU-Standard-A", language: "Hungarian-HU", gender: "FEMALE", provider: "Google Cloud", label: "hu-HU-Standard-A (FEMALE, Hungarian-HU, Google Cloud)" },
    { value: "ca-ES-Standard-A", language: "Catalan-ES", gender: "FEMALE", provider: "Google Cloud", label: "ca-ES-Standard-A (FEMALE, Catalan-ES, Google Cloud)" },
    { value: "cmn-CN-Standard-A", language: "Mandarin-CN", gender: "FEMALE", provider: "Google Cloud", label: "cmn-CN-Standard-A (FEMALE, Mandarin-CN, Google Cloud)" },
    { value: "cmn-CN-Standard-B", language: "Mandarin-CN", gender: "MALE", provider: "Google Cloud", label: "cmn-CN-Standard-B (MALE, Mandarin-CN, Google Cloud)" },
    //@todo: check below c & d
    { value: "cmn-CN-Standard-C", language: "Mandarin-CN", gender: "FEMALE", provider: "Google Cloud", label: "cmn-CN-Standard-C (FEMALE, Mandarin-CN, Google Cloud)" },
    { value: "cmn-CN-Standard-D", language: "Mandarin-CN", gender: "MALE", provider: "Google Cloud", label: "cmn-CN-Standard-D (MALE, Mandarin-CN, Google Cloud)" },
    { value: "alloy", language: "English", gender: "FEMALE", provider: "OpenAI", label: "alloy (FEMALE, OpenAI)" },
    { value: "echo", language: "English", gender: "MALE", provider: "OpenAI", label: "echo (MALE, OpenAI)" },
    { value: "fable", language: "English", gender: "MALE", provider: "OpenAI", label: "fable (MALE, OpenAI)" },
    { value: "onyx", language: "English", gender: "MALE", provider: "OpenAI", label: "onyx (MALE, OpenAI)" },
    { value: "nova", language: "English", gender: "FEMALE", provider: "OpenAI", label: "nova (FEMALE, OpenAI)" },
    { value: "shimmer", language: "English", gender: "FEMALE", provider: "OpenAI", label: "shimmer (FEMALE, OpenAI)" }
]

type VoiceOptionsProps = {
    isGoogleKey: boolean;
    isElevenLabsKey: boolean;
}
export const voiceOptions = ({ isGoogleKey, isElevenLabsKey }: VoiceOptionsProps) => {
    return allVoiceOptions
        .filter(({ provider }) => {
            if (!isGoogleKey && provider === "Google Cloud") return false;
            if (!isElevenLabsKey && provider === "ElevenLabs") return false;
            return true;
        })
}

export type VoiceCode = typeof allVoiceOptions[number]['value'];

export const getProviderByVoice = (voiceCode: VoiceCode) => {
    const voice = allVoiceOptions.find(option => option.value === voiceCode);
    if (!voice) {
        throw new Error(`Voice with value "${voiceCode}" not found`); // Throws an error if voice not found
    }
    return voice.provider;
}