import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APPNAME } from 'src/constants/constants';
import { LayoutDensityClassname, SidebarState } from 'src/types/types';
import { RootState } from '../store';
// Define types

interface SettingState {
    appName: string
    isLoading: boolean
    isVolumeOn: boolean
    initErrorMessage: string
    aboutModalIsOpen: boolean
    settingsModalIsOpen: boolean
    isValidated: boolean
    sidebarState: SidebarState
    activeSidemenu: string | null
    densityClass: LayoutDensityClassname | string
}

// Define the initial state
const initialState: SettingState = {
    appName: APPNAME,
    isLoading: false,
    isVolumeOn: true,
    initErrorMessage: "",
    aboutModalIsOpen: false,
    settingsModalIsOpen: false,
    isValidated: false,
    sidebarState: 'opened',
    activeSidemenu: null,
    densityClass: 'density-3'
};


// Create the slice
const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        handleLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isLoading = payload;
        },
        handleVolumn: (state, { payload }: PayloadAction<boolean>) => {
            state.isVolumeOn = payload;
        },
        handleInitErrorMessage: (state, { payload }: PayloadAction<string>) => {
            state.initErrorMessage = payload;
        },
        handleIsValidated: (state, { payload }: PayloadAction<boolean>) => {
            state.isValidated = payload
        },
        setSidebarState: (state, { payload }: PayloadAction<SidebarState>) => {
            state.sidebarState = payload
        },
        setActiveSidemenu: (state, { payload }: PayloadAction<string | null>) => {
            state.activeSidemenu = payload
        },
        setDensityClass: (state, { payload }: PayloadAction<LayoutDensityClassname | string>) => {
            state.densityClass = payload
        },
    },
    extraReducers: (builder) => {

    },
});

export const { handleLoading, handleVolumn, handleInitErrorMessage, handleIsValidated, setSidebarState, setActiveSidemenu, setDensityClass } = settingSlice.actions

export default settingSlice.reducer;

export const getSettingData = (state: RootState) => state?.setting;