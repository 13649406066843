import { useContext } from 'react';
import { RefsContext } from './RefsContext';

const useAvatarRefs = () => {
    const context = useContext(RefsContext);
    if (!context) {
        throw new Error('useAvatarRefs must be used within a RefsProvider');
    }
    return context;
};

export default useAvatarRefs;
