import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AVATAR, AVATAR_LABEL_VALUE, AWS, CHECK_FOR_AVATAR_PWD, CREATE_AVATAR, DELETE_AVATAR, GET_ALL_ADMIN_AVATARS, GET_ALL_AVATARS, GET_AVATAR_BY_ID, GET_AVATAR_UPDATE_TIME, UPDATE_AVATAR, UPLOAD_TO_S3, VALIDATE_AVATAR_PWD } from 'src/constants/constants';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { PaginationData } from 'src/types/types';
import { toast } from 'react-toastify';
import { AvatarPasswordData } from 'src/pages/AvatarCanvas/types/avatarCanvas.type';
import { AvatarData, FileUpload } from 'src/pages/Avatar/types/avatar.type';

interface AvatarState {
    avatarData: AvatarData | any;
    isAvatarValidated: boolean;
    isLoading: boolean;
    avatarName: string | null
}

// Define the initial state
const initialState: AvatarState = {
    avatarData: null,
    isAvatarValidated: false,
    isLoading: false,
    avatarName: null
};

export const createAvatarHandler = createAsyncThunk(
    CREATE_AVATAR,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AVATAR}/create`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllAvatarsHandler = createAsyncThunk(
    GET_ALL_AVATARS,
    async (data: PaginationData, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/all`, {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAvatarByIdHandler = createAsyncThunk(
    GET_AVATAR_BY_ID,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllAdminAvatarsHandler = createAsyncThunk(
    GET_ALL_ADMIN_AVATARS,
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/all-admin-avatars`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

// multipart formdata

export const updateAvatarHandler = createAsyncThunk(
    UPDATE_AVATAR,
    async ({ id, data }: { id: string, data: any }, { rejectWithValue }) => {
        try {
            const response = await api.put(`${AVATAR}/${id}`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const deleteAvatarHandler = createAsyncThunk(
    DELETE_AVATAR,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.delete(`${AVATAR}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);


export const uploadToS3Handler = createAsyncThunk(
    UPLOAD_TO_S3,
    async (data: FileUpload, { rejectWithValue }) => {
        try {
            const response = await api.get(AWS + '/getPutUrl', {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const checkForAvatarPwdHandler = createAsyncThunk(
    CHECK_FOR_AVATAR_PWD,
    async ({ tenantCode, avatarCode }: { tenantCode: string, avatarCode: string }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/check-for-pwd/${tenantCode}/${avatarCode}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const validateAvatarPwd = createAsyncThunk(
    VALIDATE_AVATAR_PWD,
    async (validateData: AvatarPasswordData, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AVATAR}/validate-pwd`, validateData);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAvatarUpdateTimeHandler = createAsyncThunk(
    GET_AVATAR_UPDATE_TIME,
    async ({ tenantCode, avatarCode }: { tenantCode: string, avatarCode: string }, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/get-update-time/${tenantCode}/${avatarCode}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAvatarLabelValue = createAsyncThunk(
    AVATAR_LABEL_VALUE,
    async (_: any, { rejectWithValue }) => {
        try {
            const response = await api.get(`${AVATAR}/label-value`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(handleErrors(error));
        }
    }
)


// Create the slice
const avatarSlice = createSlice({
    name: AVATAR,
    initialState,
    reducers: {
        setAvatarDataHandler: (state, { payload }: PayloadAction<any>) => {
            state.avatarData = payload
        },
        isAvatarValidatedHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.isAvatarValidated = payload
        },
        resetAvatarDataHandler: (state) => {
            state.avatarData = null
            state.isAvatarValidated = false
            state.avatarName = null;
        },
    },
    extraReducers: (builder) => {
        builder
            //createAvatarHandler
            .addCase(createAvatarHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(createAvatarHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //updateAvatarHandler
            .addCase(updateAvatarHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(updateAvatarHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //getAllAvatarsHandler
            .addCase(getAllAvatarsHandler.pending, (state) => {
            })
            .addCase(getAllAvatarsHandler.fulfilled, (state) => {
            })
            .addCase(getAllAvatarsHandler.rejected, (state, action: PayloadAction<any>) => {
                // toast.error(action.payload)
            })
            //deleteAvatarHandler
            .addCase(deleteAvatarHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })

            //checkForAvatarPwdHandler
            .addCase(checkForAvatarPwdHandler.fulfilled, (state, action: PayloadAction<any>) => {
                if (action.payload.isAvatarValidated) {
                    state.avatarData = action.payload.data
                    state.avatarName = action.payload.data.name
                } else {
                    state.avatarName = action.payload.data //has password, set name for dispalying
                }

                state.isAvatarValidated = action.payload.isAvatarValidated

                state.isLoading = false;
            })
            .addCase(checkForAvatarPwdHandler.rejected, (state, action: PayloadAction<any>) => {
                state.isAvatarValidated = false
                state.isLoading = false;
                toast.error(action.payload)
            })
            .addCase(checkForAvatarPwdHandler.pending, (state, action: PayloadAction<any>) => {
                state.isLoading = true;
            })

            //validateAvatarPwd
            .addCase(validateAvatarPwd.fulfilled, (state, action: PayloadAction<any>) => {
                state.isAvatarValidated = true
                state.avatarData = action.payload.data
            })
            .addCase(validateAvatarPwd.rejected, (state, action: PayloadAction<any>) => {
                state.isAvatarValidated = false
                toast.error(action.payload)
            })
            // uploadToS3Handler
            .addCase(uploadToS3Handler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //getAvatarUpdateTimeHandler
            .addCase(getAvatarUpdateTimeHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })

    },
});

export const { isAvatarValidatedHandler, setAvatarDataHandler, resetAvatarDataHandler } = avatarSlice.actions
export default avatarSlice.reducer;
