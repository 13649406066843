import { RefsProvider } from './RefContext/RefsContext'
import AvatarCanvas from './AvatarCanvas'

const AvatarCanvasWithContext = () => {
    return (
        <RefsProvider>
            <AvatarCanvas />
        </RefsProvider>
    )
}

export default AvatarCanvasWithContext