import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import { Loader } from 'src/components/UI/Loader';
import { AVATAR_LISTING, CHANGE_PASSWORD, CONVERSATION_LISTING, DASHBOARD, PROFILE, ROLE_PERMISSIONS_LISTING, SUBSCRIPTION, TENANT_LISTING, USER_LISTING, VIDEO } from 'src/constants/constants';
import { usePermissionGuard } from 'src/hooks/usePermissionGuard';
import AvatarCanvasWithContext from 'src/pages/AvatarCanvas/AvatarCanvasWithContext'; // DON'T IMPORT IT USING LAZY LOADING
import ProfileChangePassword from 'src/pages/Profile/ProfileChangePassword';
import { IPrivateRoute } from 'src/types/routes.type';


// Pages
const UserLayout = lazy(() => import('../layout/UserLayout'));
const ChangePassword = lazy(() => import('../pages/Auth/ChangePassword'));
const Profile = lazy(() => import('../pages/Profile/Profile'));
const Subscription = lazy(() => import('../pages/Subscription/Subscription'));
const TenantListing = lazy(() => import('../pages/Tenant/TenantListing'));
const UserListing = lazy(() => import('../pages/User/UserListing'));
const ConversationListing = lazy(() => import('../pages/Conversation/ConversationListing'));
const RolePermissionsListing = lazy(() => import('../pages/RolePermissions/RolePermissionListing'));
const AvatarListing = lazy(() => import('../pages/Avatar/AvatarListing'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Heygen = lazy(() => import('../pages/Heygen/Heygen'));


const PrivateRoutes = () => {

    // Custom Hooks
    const { validatePermissions } = usePermissionGuard();


    const routes: IPrivateRoute[] = [
        {
            key: 'tenant-listing',
            path: TENANT_LISTING,
            element: <TenantListing />,
            show: validatePermissions([], { forceCheckIs: 'ADMIN' })
        },
        {
            key: 'user-listing',
            path: USER_LISTING,
            element: <UserListing />,
            show: validatePermissions(['user-read'], { forceCheckIs: 'USER' })
        },
        {
            key: 'conversation-listing',
            path: CONVERSATION_LISTING,
            element: <ConversationListing />,
            show: validatePermissions(['conversation-read'])
        },
        {
            key: 'role-permissions-listing',
            path: ROLE_PERMISSIONS_LISTING,
            element: <RolePermissionsListing />,
            show: validatePermissions(['role-read'], { forceCheckIs: 'USER' })
        },
        {
            key: 'avatar-listing',
            path: AVATAR_LISTING,
            element: <AvatarListing />,
            show: validatePermissions(['avatar-read'])
        },
        {
            key: 'subscription',
            path: SUBSCRIPTION,
            element: <Subscription />,
            show: validatePermissions([], { forceCheckIs: 'OWNER' })
        },
        {
            key: 'profile/change-password',
            path: PROFILE + CHANGE_PASSWORD + '/:userId',
            element: <ProfileChangePassword />,
            show: validatePermissions([], { forceCheckIs: 'USER' })
        },
        {
            key: 'profile',
            path: PROFILE,
            element: <Profile />,
            show: validatePermissions([], { forceCheckIs: 'USER' })
        },
        {
            key: 'avatar-canvas',
            path: '/bot/:tenantCode/:avatarCode',
            element: <AvatarCanvasWithContext />,
            show: true
        },
        {
            key: 'dashboard',
            path: DASHBOARD,
            element: <Dashboard />,
            show: true
        },
        {
            key: 'video-listing',
            path: VIDEO,
            element: <Heygen />,
            show: validatePermissions(['video-read'])
        },
        {
            key: 'not-found',
            path: '*',
            element: <Navigate to={DASHBOARD} />,
            show: true
        }
    ]

    const changePasswordRoute = {
        key: 'change-password',
        path: CHANGE_PASSWORD + '/:userId',
        element: <ChangePassword />,
        show: validatePermissions([], { forceCheckIs: 'USER' })
    }

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route element={<UserLayout />}>
                    {
                        routes?.map((route, i) => route?.show && <Route key={`pvt-route-${route?.key}-${i}`} path={route?.path} element={route.element} />)
                    }
                </Route>

                {/* ChangePassword route outside UserLayout */}
                {changePasswordRoute.show && (
                    <Route key={`pvt-route-${changePasswordRoute.key}`} path={changePasswordRoute.path} element={changePasswordRoute.element} />
                )}
            </Routes>
        </Suspense>
    )
}

export default PrivateRoutes
