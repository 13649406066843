import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field } from "formik";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import ErrorMessageFormik from "src/components/Form/ErrorMessageFormik";
import { FORGOT_PASSWORD } from "src/constants/constants";

interface PasswordFieldProps {
    name: string;
    label: string;
    autoComplete?: string | undefined
    hasLink?: boolean
    placeholder?: string
    color?: string
}

const PasswordField: FC<PasswordFieldProps> = ({
    name,
    label,
    autoComplete = undefined,
    hasLink = false,
    placeholder = '',
    color = 'primary'
}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div>
            <div className='hstack justify-content-between flex-wrap gap-2'>
                <label htmlFor={name} className='custom-form-label'>{label}</label>
                {hasLink && <Link to={FORGOT_PASSWORD} className="custom-form-label">Forgot?</Link>}
            </div>
            <div className="position-relative">
                <Field
                    autoComplete={autoComplete}
                    type={showPassword ? "text" : "password"}
                    id={name}
                    name={name}
                    className="custom-form-control pe-4" 
                    placeholder={placeholder}
                />
                <button type="button" className="btn btn-0 position-absolute translate-middle-y top-50 end-0 me-1" style={{ width: 46, marginTop: 1.5 }} onClick={() => setShowPassword(!showPassword)}>
                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} className={`text-${color}`} />
                </button>
            </div>
            <ErrorMessageFormik name={name} />
        </div>
    );
};

export default PasswordField