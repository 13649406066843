import { jwtDecode } from "jwt-decode";
import _ from "lodash";

export const handleErrors = (error: any) => {
    if (error.response && error.response.data) {
        const err = error.response.data.message || 'An error occurred'
        return err;
    }
    return 'Network error';
};

export function isWebview(): boolean {
    if (typeof window === undefined) {
        return false;
    }

    let navigator = window.navigator;

    const standalone = (navigator as any).standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
    const ios_ipad_webview = ios && !safari;

    return ios ? (!standalone && !safari) || ios_ipad_webview : userAgent.includes("wv");
}
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const generatePassword = (length = 8) => {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const specialCharacters = '!@#$%^&*()_+-=[]{}|;:,.<>?';

    const allCharacters = lowercase + uppercase + numbers + specialCharacters;

    let password = '';

    // Ensure at least one character from each category
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];

    // Fill the remaining length of the password
    for (let i = 4; i < length; i++) {
        password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    // Shuffle the password to ensure randomness
    password = password.split('').sort(() => Math.random() - 0.5).join('');

    return password;
}

export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,40}$/;

export const isValidColor = (color: string) => {
    const s = new Option().style;
    s.color = color;
    return s.color !== '';
}

export const mbTobytes = (mb: number) => {
    return 1024 * 1024 * mb
}

export const useSearchParams = () => {
    return new URL(window.location.href).searchParams;
};

export const scrollToBottom = (ref: HTMLElement) => {
    ref.scrollIntoView({ block: "nearest", inline: "start", behavior: "smooth" });
};


export const isTokenExpired = (token: string) => {
    if (!token) return true;
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp && decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Error decoding token:', error);
        return true;
    }
};

// Function to find the differences and return only the updated values and false if no updated values
export function getDifferences(defaultValues: any, formValues: any, keysToSkip: string[] = []) {
    const isEqual = deepEqual(defaultValues, formValues, keysToSkip);
    if (isEqual) return false; // If objects are equal, return false.

    const differences: any = {};

    // Iterate over the keys of defaultValues and compare with formValues
    Object.keys(defaultValues).forEach((key) => {
        // Skip the key if it is in keysToSkip, and only check for differences for the remaining keys
        if (!keysToSkip.includes(key) && defaultValues[key] !== formValues[key]) {
            differences[key] = formValues[key]; // Only store the new value (from formValues)
        }
    });

    return differences; // Return the differences without including keys from keysToSkip
}


function deepEqual(obj1: any, obj2: any, keysToSkip: string[] = []): boolean {
    if (obj1 === obj2) return true; // Same reference or both are null/undefined
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1).filter(key => !keysToSkip.includes(key));
    const keys2 = Object.keys(obj2).filter(key => !keysToSkip.includes(key));

    if (keys1.length !== keys2.length) return false; // Different number of keys

    for (let key of keys1) {
        if (!keys2.includes(key)) return false; // Key is missing in the second object
        if (!deepEqual(obj1[key], obj2[key], keysToSkip)) return false; // Recursively compare nested values
    }

    return true;
}

export function getDifferencesStringify(defaultValues: any, formValues: any) {
    const isChanged = JSON.stringify(defaultValues) !== JSON.stringify(formValues);
    return isChanged
}

// pwd: Yup.string()
// .required("This field is required").matches(/^.{6,}$/, 'Password must be at least 6 characters long')
// .matches(/^.{0,40}$/, 'Password must be at most 40 characters long')
// .matches(/(?=.*[a-z])/, 'Password must contain at least one lowercase letter')
// .matches(/(?=.*[A-Z])/, 'Password must contain at least one uppercase letter')
// .matches(/(?=.*\d)/, 'Password must contain at least one digit')
// .matches(/(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, 'Password must contain at least one special character')
// .matches(/^\S*$/, 'No spaces allowed'),

export const getChangedValues = (currentValues: any, initialValues: any) => {
    return _.reduce(
        currentValues,
        (result: any, value: any, key: any) => {
            if (!_.isEqual(value, initialValues[key])) {
                result[key] = value;
            }
            return result;
        },
        {}
    );
};

export const defaultMaxSizeOfAvatarFile = process.env?.REACT_APP_MAX_SIZE_OF_AVATAR_FILE ? Number(process.env?.REACT_APP_MAX_SIZE_OF_AVATAR_FILE) : 20;
export const defaultMaxSizeOfAssistantFile = process.env?.REACT_APP_MAX_SIZE_OF_ASSISTANT_FILE ? Number(process.env?.REACT_APP_MAX_SIZE_OF_ASSISTANT_FILE) : 10;
export const defaultMaxNumberOfAssistantFiles = process.env?.REACT_APP_MAX_NUMBER_OF_ASSISTANT_FILES ? Number(process.env?.REACT_APP_MAX_NUMBER_OF_ASSISTANT_FILES) : 3;

export const validateFileSizeExceeds = (files: File | File[] | FileList, maxSize = defaultMaxSizeOfAssistantFile) => {
    let isSizeExceed = false;
    if (Array.isArray(files)) {
        for (const file of files) {
            if (file?.size >= mbTobytes(maxSize)) {
                isSizeExceed = true;
                break;
            }
        }
    }
    else if (files instanceof FileList) {
        for (const key in files) {
            if (Object.prototype.hasOwnProperty.call(files, key)) {
                const file = files?.[key]
                if (file?.size >= mbTobytes(maxSize)) {
                    isSizeExceed = true;
                    break;
                }
            }
        }
    }
    else {
        if (files?.size >= mbTobytes(maxSize)) {
            isSizeExceed = true
        }
    }
    return isSizeExceed;
}

export const addDays = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + (days === 1 ? days : (days - 1)));
    return newDate;
}

export const substractDays = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() - (days === 1 ? days : (days - 1)));
    return newDate;
}

export const addMonths = (date: Date, months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(date?.getMonth() + (months === 1 ? months : (months - 1)));
    return newDate
}

export const minPasswordAvatar = process.env.REACT_APP_AVATAR_MIN_PASSWORD_LENGTH ? Number(process.env.REACT_APP_AVATAR_MIN_PASSWORD_LENGTH) : 3
export const minPassword = process.env.REACT_APP_MIN_PASSWORD_LENGTH ? Number(process.env.REACT_APP_MIN_PASSWORD_LENGTH) : 6
export const maxPassword = process.env.REACT_APP_MAX_PASSWORD_LENGTH ? Number(process.env.REACT_APP_MAX_PASSWORD_LENGTH) : 20


export const pageLimit = 10;

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || "http://localhost:4000";

export const tenantKeysImplementation = process.env.REACT_APP_TENANT_KEYS_IMPLEMENTATION === "false" ? false : true || false