import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { toast } from 'react-toastify';
import { CREATE_VIDEO, VIDEO, DELETE_VIDEO, GET_ALL_VIDEOS, CHECK_VIDEO_STATUS, GET_VIDEO_BY_ID } from 'src/constants/constants';
import { CreateCustomerRequestData } from 'src/pages/Heygen/types/heygen.type';

interface VideoState { }
const initialState: VideoState = {};

export const createCustomerVideoHandler = createAsyncThunk(
    CREATE_VIDEO,
    async (data: CreateCustomerRequestData, { rejectWithValue }) => {
        try {
            const response = await api.post(`${VIDEO}/create`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getVideoByIdHandler = createAsyncThunk(
    GET_VIDEO_BY_ID,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${VIDEO}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);


export const getAllCustomerVideosHandler = createAsyncThunk(
    GET_ALL_VIDEOS,
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get(`${VIDEO}/all`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const deleteVideoHandler = createAsyncThunk(
    DELETE_VIDEO,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.delete(`${VIDEO}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const checkVideoStatusHandler = createAsyncThunk(
    CHECK_VIDEO_STATUS,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${VIDEO}/check-status/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);


// Create the slice
const videoSlice = createSlice({
    name: VIDEO,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCustomerVideoHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action?.payload?.message)
            })
            .addCase(createCustomerVideoHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(getVideoByIdHandler.fulfilled, (state, action: PayloadAction<any>) => {
                // toast.success(action?.payload?.message)
            })
            .addCase(getVideoByIdHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(getAllCustomerVideosHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action?.payload?.message)
            })
            .addCase(getAllCustomerVideosHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(deleteVideoHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action?.payload?.message)
            })
            .addCase(deleteVideoHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(checkVideoStatusHandler.fulfilled, (state, action: PayloadAction<any>) => {
                // toast.success(action?.payload?.message)
            })
            .addCase(checkVideoStatusHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
    },
});

// export const { } = videoSlice.actions
export default videoSlice.reducer;
