import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SIGNIN, SIGNUP, RESET_PASSWORD, FORGOT_PASSWORD, CHANGE_PASSWORD, AUTH, LOGOUT_USER } from 'src/constants/constants';
import { toast } from 'react-toastify';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { getLoggedInUserDetails } from './userSlice';
import { Admin, IChangePasswordRequest, IResetPassword, ISignin } from 'src/pages/Auth/types/auth.type';

interface AuthState {
    user: any | null // change any to user after changes and testing with proper user interface
    isUser: boolean
    admin: Admin | null
    isAdmin: boolean
    forceChangePwd: boolean
}

// Define the initial state
const initialState: AuthState = {
    user: null,
    isUser: false,
    admin: null,
    isAdmin: false,
    forceChangePwd: false
};

// Create async thunks for authentication operations
export const signInHandler = createAsyncThunk(
    SIGNIN,
    async (data: ISignin, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AUTH}${SIGNIN}`, data)
            return response.data;
        } catch (error: any) {
            const err = error?.response?.status !== 402 ? handleErrors(error) : null;
            return rejectWithValue(err);
        }
    }
);

export const signUpHandler = createAsyncThunk(
    SIGNUP,
    async (data: ISignin, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AUTH}${SIGNUP}`, data)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const resetPasswordHandler = createAsyncThunk(
    RESET_PASSWORD,
    async (data: IResetPassword, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AUTH}${RESET_PASSWORD}`, data)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const forgetPasswordHandler = createAsyncThunk(
    FORGOT_PASSWORD,
    async (email: string, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AUTH}${FORGOT_PASSWORD}`, { email })
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const changePasswordHandler = createAsyncThunk(
    CHANGE_PASSWORD,
    async (data: IChangePasswordRequest, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AUTH}${CHANGE_PASSWORD}`, data)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const logoutUserHandler = createAsyncThunk(
    LOGOUT_USER,
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.post(`${AUTH}${LOGOUT_USER}`);
            return response?.data;
        } catch (error) {
            return rejectWithValue(handleErrors(error));
        }
    }
)

// Create the slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userHandler: (state, { payload }: PayloadAction<any>) => {
            state.user = payload;
            state.isUser = true;
            if (payload.roleUser) {
                state.admin = null;
                state.isAdmin = false;
            }
        },
        adminHandler: (state, { payload }: PayloadAction<any>) => {
            state.admin = payload;
            state.isAdmin = true;
            state.user = null;
            state.isUser = false;
        },
        logoutHandler: () => { },
        forceChangePwdHandler: (state, { payload }: PayloadAction<boolean>) => {
            state.forceChangePwd = payload;
        },
        setTenantExpiration: (state, { payload }: PayloadAction<string>) => {
            state.user.user.tenantUser.expiredAt = payload;
        },
        updatePermissions: (state, { payload }: PayloadAction<any>) => {
            return (state = {
                ...state,
                user: {
                    ...state?.user,
                    permissions: payload
                }
            })
        }
    },
    extraReducers: (builder) => {
        builder
            //signUpHandler
            .addCase(signUpHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })

            //signInHandler
            .addCase(signInHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })

            //resetPasswordHandler
            .addCase(resetPasswordHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(resetPasswordHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })

            //changePasswordHandler
            .addCase(changePasswordHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(changePasswordHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })

            //forgetPasswordHandler
            .addCase(forgetPasswordHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(forgetPasswordHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(getLoggedInUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
                return (state = {
                    ...state,
                    user: {
                        ...state.user,
                        user: {
                            ...state.user?.user,
                            ...action.payload?.user
                        }
                    }
                })
            })
    },
});

export const { userHandler, adminHandler, logoutHandler, forceChangePwdHandler, setTenantExpiration, updatePermissions } = authSlice.actions
export default authSlice.reducer;
