import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CHANGE_PASSWORD_IN, CREATE_USER, DASHBOARD, DELETE_USER, GET_ALL_USERS, GET_USER_BY_ID, LOGGED_IN_USER, UPDATE_TENANT_KEYS, UPDATE_USER, USER } from 'src/constants/constants';
import { api } from 'src/common/axiosInstance';
import { handleErrors } from 'src/common/commonUtils';
import { PaginationData } from 'src/types/types';
import { EditTenantKeys } from 'src/pages/Profile/Profile';
import { toast } from 'react-toastify';
import { IChangePasswordInRequest } from 'src/pages/Profile/types/profile.type';

interface UserState { }
const initialState: UserState = {};

export const createUserHandler = createAsyncThunk(
    CREATE_USER,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await api.post(`${USER}/create`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getAllUsersHandler = createAsyncThunk(
    GET_ALL_USERS,
    async (data: PaginationData, { rejectWithValue }) => {
        try {
            const response = await api.get(`${USER}/all`, {
                params: data
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getUserByIdHandler = createAsyncThunk(
    GET_USER_BY_ID,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.get(`${USER}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const updateUserHandler = createAsyncThunk(
    UPDATE_USER,
    async ({ id, data }: { id: string, data: any }, { rejectWithValue }) => {
        try {
            const response = await api.put(`${USER}/${id}`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const updateTenantKeysHandler = createAsyncThunk(
    UPDATE_TENANT_KEYS,
    async (data: EditTenantKeys, { rejectWithValue }) => {
        try {
            const response = await api.post(`${USER}/updateKeys`, data);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const deleteTenantUserByUuid: any = createAsyncThunk(
    DELETE_USER,
    async (id: string, { rejectWithValue }) => {
        try {
            const response = await api.delete(`${USER}/${id}`);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getLoggedInUserDetails = createAsyncThunk(
    LOGGED_IN_USER,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await api.get(`${USER}${LOGGED_IN_USER}`);
            return response?.data?.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const changePasswordInHandler = createAsyncThunk(
    CHANGE_PASSWORD_IN,
    async (data: IChangePasswordInRequest, { rejectWithValue }) => {
        try {
            const response = await api.post(`${USER}${CHANGE_PASSWORD_IN}`, data)
            return response.data;
        } catch (error: any) {
            return rejectWithValue(handleErrors(error));
        }
    }
);

export const getDashboardDetails = createAsyncThunk(
    DASHBOARD,
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get(`${USER}${DASHBOARD}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(handleErrors(error));
        }
    }
)

// Create the slice
const userSlice = createSlice({
    name: USER,
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            //updateTenantKeysHandler
            .addCase(updateTenantKeysHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action?.payload?.message)
            })
            .addCase(updateTenantKeysHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //updateUserHandler
            .addCase(updateUserHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action?.payload?.message)
            })
            .addCase(updateUserHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            //createUserHandler
            .addCase(createUserHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action?.payload?.message)
            })
            .addCase(createUserHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(deleteTenantUserByUuid.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload?.message);
            })
            .addCase(deleteTenantUserByUuid.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action?.payload);
            })
            //changePasswordInHandler
            .addCase(changePasswordInHandler.fulfilled, (state, action: PayloadAction<any>) => {
                toast.success(action.payload.message)
            })
            .addCase(changePasswordInHandler.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload)
            })
            .addCase(getDashboardDetails.rejected, (state, action: PayloadAction<any>) => {
                toast.error(action.payload);
            })

    },
});

// export const { } = userSlice.actions
export default userSlice.reducer;
