import { ENUM_CHAT_BY } from "src/types/enum";
import { PaginationData } from "../../../types/types"
import { Humanoid } from "src/common/Babylon/Humanoid";

export type HumanoidRef = React.MutableRefObject<Humanoid | null>;
export type CurrentSoundRef = React.MutableRefObject<HTMLAudioElement | null>;

interface UserData {
    uuid: string
    fName: string
    lName: string
}

interface AvatarDetails {
    name: string
}

export interface IConversationsGroupByThreadRes {
    mapId?: number;
    user: UserData
    avatar: AvatarDetails
    uuid: string
}

export interface ConversationPaginateData extends PaginationData {
    avatarUuid?: string;
}

export interface IConversationDetails {
    userData: {
        uuid: string;
        fName: string;
        lName: string;
    };
    avatarData: {
        uuid: string;
        name: string;
    }
}

export interface IVoiceData {
    voice: string | any
    voiceProvider: string
}

export interface IChatMessage {
    content: string
    role: ENUM_CHAT_BY
};

export interface MessageContent {
    message: string;
    role: ENUM_CHAT_BY
}

export interface ISendMessageData {
    messagesData: IChatMessage[]
    voiceData: IVoiceData
    assistantId: string
    threadId: string | null
}

export type BotResponse = {
    text: string
    audio: string
    threadId: string
}


export const ASSISTANT = ENUM_CHAT_BY.ASSISTANT
export const CHAT_BY_USER = ENUM_CHAT_BY.USER
// export const CHAT_BY_SYSTEM = 'system'